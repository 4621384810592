export enum AppPaths {
    HOME = '/',
    OAUTH_CREDENTIALS = '/oauth-credentials',
    INTEGRATION_SERVICE = '/integration-service',
    INTEGRATION_KEYS = '/integration-keys',
    EVENTS = '/events',
    FLOWS = '/flows',
    FLOWS_TRIGGERS = '/flows-triggers',
}

export enum AuthUrl {
    refresh = '/auth/refresh/',
}
