import {createAsyncThunk} from "@reduxjs/toolkit";
import {requestHandle} from "@/utils/requestHandle";
import {Api} from "@/api/apiService";

export const getProviders = createAsyncThunk(
    'oauth/getProviders',
    async (_, thunkAPI) => {
        const response = await requestHandle(() => Api.OAuth.getOauthProvidersOauthProvidersGet(), thunkAPI)
        return response
    }
)
