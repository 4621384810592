import {IBaseStateField} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getNewStateByStatus} from '@/utils/getNewStateByStatus'

import {IGetFlowsReturn} from '@/store/actionTypes/flows'
import {getFlows} from '@/store/entities/flows/actions'

interface IState {
    getFlowsState: IBaseStateField
    flows: IGetFlowsReturn | null
}

const initialState: IState = {
    getFlowsState: getNewStateByStatus('base'),
    flows: null
}

export const flowsSlice = createSlice({
    name: 'flows',
    initialState,
    reducers: {},
    extraReducers: {
        [getFlows.pending.type]: (state) => {
            state.getFlowsState = getNewStateByStatus('pending')
        },
        [getFlows.fulfilled.type]: (state, action: PayloadAction<IGetFlowsReturn>) => {
            state.getFlowsState = getNewStateByStatus('fulfilled')
            state.flows = action.payload
        },
        [getFlows.rejected.type]: (state) => {
            state.getFlowsState = getNewStateByStatus('rejected')
        },
    }
})
