import {createAsyncThunk} from '@reduxjs/toolkit'

import {Api} from '@/api/apiService'

import {requestHandle} from '@/utils/requestHandle'

export const createApiKeyIntegrationServices = createAsyncThunk(
    'integration/createApiKeyIntegrationServices',
    async (expires_at: number | undefined, thunkAPI) => {
        const response = await requestHandle(() => Api.ServiceIntegration.createApiKeyIntegrationServicesKeysPost(expires_at), thunkAPI)
        return response
    }
)

export const deactivateApiKeyIntegration = createAsyncThunk(
    'integration/deactivateApiKeyIntegration',
    async (_, thunkAPI) => {
        const response = await requestHandle(() => Api.ServiceIntegration.deactivateApiKeyIntegrationKeysDeactivatePatch(), thunkAPI)
        return response
    }
)

export const deleteApiKeyIntegration = createAsyncThunk(
    'integration/deleteApiKeyIntegration',
    async (_, thunkAPI) => {
        const response = await requestHandle(() => Api.ServiceIntegration.deleteApiKeyIntegrationKeysDeletePatch(), thunkAPI)
        return response
    }
)
