import {cancelErrors} from '@/types/cancelErrors'

import axiosLib, {AxiosInstance, AxiosRequestConfig} from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import {createApiUrl} from '@/api/createApiUrl'
import {IntegrationService} from "@/api/IntegrationService";
import {EventsApi, FlowsApi, FlowsTriggersApi} from '@/api/swaggerApi/ed'
import {
    AuthApi, OAuthApi, OAuthCredentialsApi,
    PasswordRecoveryApi,
    RegistrationApi,
    ServiceIntegrationApi
} from '@/api/swaggerApi/oauth'
import {TokenService} from '@/api/TokenService'

import {AuthUrl} from '@/routes/routes-enums'

const oauthUrl = createApiUrl(
    process.env.REACT_APP_DEVELOPMENT_OAUTH_API_URL,
    process.env.REACT_APP_OAUTH_API_URL
) as string

const edUrl = createApiUrl(
    process.env.REACT_APP_DEVELOPMENT_ED_API_URL,
    process.env.REACT_APP_ED_API_URL
) as string

async function getRefreshToken() {
    const refreshToken = TokenService.getRefreshedToken()
    return axiosLib.post(oauthUrl + AuthUrl.refresh, undefined, {
        params: {refresh_token: refreshToken},
    })
}

export class Api {
    static Auth: AuthApi

    static Registration: RegistrationApi

    static PasswordRecovery: PasswordRecoveryApi

    static ServiceIntegration: ServiceIntegrationApi

    static OAuthCredentials: OAuthCredentialsApi

    static OAuth: OAuthApi

    static Events: EventsApi

    static Flows: FlowsApi

    static FlowsTriggers: FlowsTriggersApi

    oauthService: AxiosInstance

    edService: AxiosInstance

    constructor() {
        this.oauthService = this.initService(oauthUrl)
        this.edService = this.initService(edUrl)
    }

    private initService = (serviceUrl: string): AxiosInstance => {
        let service = axiosLib.create({
            baseURL: serviceUrl,
            withCredentials: true,
            httpsAgent: {rejectUnauthorized: false},
        })

        service.interceptors.response.use(this.handleSuccess, this.handleError)
        service.interceptors.request.use(
            (request) => {
                if (!request?.headers) {
                    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`)
                }
                const token = TokenService.getToken()
                request.headers.Authorization = 'Bearer ' + token
                request.headers[request.baseURL === edUrl ? 'X-Integration-Key' : 'X-Integration-Token'] = IntegrationService.getIntegrationToken();

                return request
            },
            (error) => Promise.reject(error)
        )
        createAuthRefreshInterceptor(service, this.refreshAuthLogic, {
            retryInstance: service,
            onRetry: (requestConfig) => ({...requestConfig, baseURL: serviceUrl}),
        })

        return service
    }

    init = () => {
        Api.Auth = new AuthApi(undefined, undefined, this.oauthService)
        Api.Registration = new RegistrationApi(undefined, undefined, this.oauthService)
        Api.PasswordRecovery = new PasswordRecoveryApi(undefined, undefined, this.oauthService)
        Api.OAuthCredentials = new OAuthCredentialsApi(undefined, undefined, this.oauthService)
        Api.OAuth = new OAuthApi(undefined, undefined, this.oauthService)

        Api.ServiceIntegration = new ServiceIntegrationApi(undefined, undefined, this.edService)
        Api.Events = new EventsApi(undefined, undefined, this.edService)
        Api.Flows = new FlowsApi(undefined, undefined, this.edService)
        Api.FlowsTriggers = new FlowsTriggersApi(undefined, undefined, this.edService)
    }

    handleSuccess(response: AxiosRequestConfig) {
        return response
    }

    async handleError(error: any) {
        if (axiosLib.isCancel(error)) return Promise.reject(cancelErrors.cancelToken)
        return Promise.reject(error)
    }

    refreshAuthLogic = async (failedRequest: any) => {
        const originalConfig = failedRequest.config
        // if (!TokenService.isRefreshedTokenExists()) return Promise.resolve()
        try {
            const {data} = await getRefreshToken()
            if (data.success) {
                const {auth_token, refresh_token} = data.result
                TokenService.setToken(auth_token)
                TokenService.setRefreshedToken(refresh_token)
                originalConfig.headers.Authorization = `Bearer ${auth_token}`
                return await Promise.resolve()
            } else {
                // this.dispatch(logoutUser())

                return await Promise.reject()
            }
        } catch (e) {
            // this.dispatch(logoutUser())
            return Promise.reject()
        }
    }

    _getProgress(callback: (percentCompleted: number) => void = () => {}) {
        return {
            onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
                callback(percentCompleted)
            },
        }
    }
}

export function getCancelTokenSource() {
    return axiosLib.CancelToken.source()
}

export function updateCancelToken(data: any) {
    if (data === undefined || data === null) return {}
    if (data.source) data.source.cancel('Operation canceled due to new request.')
    data.source = getCancelTokenSource()
    return data.source.token
}
