import {IBaseStateField} from "@/types/type";

type BaseStateFieldStatus = 'pending' | 'fulfilled' | 'rejected' | 'base';

export const getNewStateByStatus = (status: BaseStateFieldStatus): IBaseStateField => {
    switch (status) {
        case "base":
            return {
                isLoading: false,
                isError: false,
                isSuccess: false,
            }
        case "fulfilled":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
            }
        case "pending":
            return {
                isLoading: true,
                isError: false,
                isSuccess: false,
            }
        case "rejected":
            return {
                isLoading: false,
                isError: true,
                isSuccess: false,
            }
    }
}
