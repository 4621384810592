/* tslint:disable */
/* eslint-disable */
/**
 * Event Driven Application
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AppsApiAppEventsSchemasEventSchemaOut
 */
export interface AppsApiAppEventsSchemasEventSchemaOut {
    /**
     * 
     * @type {any}
     * @memberof AppsApiAppEventsSchemasEventSchemaOut
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiAppEventsSchemasEventSchemaOut
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiAppEventsSchemasEventSchemaOut
     */
    'description': any;
}
/**
 * 
 * @export
 * @interface AppsApiAppFlowTriggersSchemasFlowTriggerSchema
 */
export interface AppsApiAppFlowTriggersSchemasFlowTriggerSchema {
    /**
     * 
     * @type {any}
     * @memberof AppsApiAppFlowTriggersSchemasFlowTriggerSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiAppFlowTriggersSchemasFlowTriggerSchema
     */
    'flow_id': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiAppFlowTriggersSchemasFlowTriggerSchema
     */
    'event_id': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiAppFlowTriggersSchemasFlowTriggerSchema
     */
    'is_enabled': any;
}
/**
 * 
 * @export
 * @interface CreateEventSchema
 */
export interface CreateEventSchema {
    /**
     * Event name
     * @type {any}
     * @memberof CreateEventSchema
     */
    'name': any;
    /**
     * Event description
     * @type {any}
     * @memberof CreateEventSchema
     */
    'description': any;
}
/**
 * 
 * @export
 * @interface CreateFlowTrigger
 */
export interface CreateFlowTrigger {
    /**
     * Flow ID
     * @type {any}
     * @memberof CreateFlowTrigger
     */
    'flow_id': any;
    /**
     * Event ID
     * @type {any}
     * @memberof CreateFlowTrigger
     */
    'event_id': any;
    /**
     * Enable flow triggers
     * @type {any}
     * @memberof CreateFlowTrigger
     */
    'is_enabled': any;
}
/**
 * 
 * @export
 * @interface DataResponseEventSchemaOut
 */
export interface DataResponseEventSchemaOut {
    /**
     * 
     * @type {any}
     * @memberof DataResponseEventSchemaOut
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof DataResponseEventSchemaOut
     */
    'message'?: any;
    /**
     * 
     * @type {FastapiScaffoldResponsesEventSchemaOut}
     * @memberof DataResponseEventSchemaOut
     */
    'data': FastapiScaffoldResponsesEventSchemaOut;
}
/**
 * 
 * @export
 * @interface DataResponseFlowTriggerSchema
 */
export interface DataResponseFlowTriggerSchema {
    /**
     * 
     * @type {any}
     * @memberof DataResponseFlowTriggerSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof DataResponseFlowTriggerSchema
     */
    'message'?: any;
    /**
     * 
     * @type {FastapiScaffoldResponsesFlowTriggerSchema}
     * @memberof DataResponseFlowTriggerSchema
     */
    'data': FastapiScaffoldResponsesFlowTriggerSchema;
}
/**
 * 
 * @export
 * @interface DataResponseStr
 */
export interface DataResponseStr {
    /**
     * 
     * @type {any}
     * @memberof DataResponseStr
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof DataResponseStr
     */
    'message'?: any;
    /**
     * 
     * @type {Str}
     * @memberof DataResponseStr
     */
    'data': Str;
}
/**
 * 
 * @export
 * @interface ErrorDetails
 */
export interface ErrorDetails {
    /**
     * 
     * @type {any}
     * @memberof ErrorDetails
     */
    'type': any;
    /**
     * 
     * @type {any}
     * @memberof ErrorDetails
     */
    'loc': any;
    /**
     * 
     * @type {any}
     * @memberof ErrorDetails
     */
    'msg': any;
    /**
     * 
     * @type {any}
     * @memberof ErrorDetails
     */
    'input': any;
    /**
     * 
     * @type {any}
     * @memberof ErrorDetails
     */
    'ctx'?: any;
}
/**
 * 
 * @export
 * @interface EventSchema
 */
export interface EventSchema {
    /**
     * 
     * @type {any}
     * @memberof EventSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof EventSchema
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof EventSchema
     */
    'description': any;
}
/**
 * 
 * @export
 * @interface FastapiScaffoldResponsesEventSchemaOut
 */
export interface FastapiScaffoldResponsesEventSchemaOut {
    /**
     * 
     * @type {AppsApiAppEventsSchemasEventSchemaOut}
     * @memberof FastapiScaffoldResponsesEventSchemaOut
     */
    'event': AppsApiAppEventsSchemasEventSchemaOut;
}
/**
 * 
 * @export
 * @interface FastapiScaffoldResponsesFlowTriggerSchema
 */
export interface FastapiScaffoldResponsesFlowTriggerSchema {
    /**
     * 
     * @type {AppsApiAppFlowTriggersSchemasFlowTriggerSchema}
     * @memberof FastapiScaffoldResponsesFlowTriggerSchema
     */
    'flow_trigger': AppsApiAppFlowTriggersSchemasFlowTriggerSchema;
}
/**
 * 
 * @export
 * @interface FlowSchema
 */
export interface FlowSchema {
    /**
     * 
     * @type {any}
     * @memberof FlowSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof FlowSchema
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof FlowSchema
     */
    'description': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceApiKeyDataSchema
 */
export interface IntegrationServiceApiKeyDataSchema {
    /**
     * 
     * @type {IntegrationServiceApiKeyOutSchema}
     * @memberof IntegrationServiceApiKeyDataSchema
     */
    'api_key': IntegrationServiceApiKeyOutSchema;
}
/**
 * 
 * @export
 * @interface IntegrationServiceApiKeyOutSchema
 */
export interface IntegrationServiceApiKeyOutSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceApiKeyOutSchema
     */
    'api_key': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceApiKeyResponseSchema
 */
export interface IntegrationServiceApiKeyResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceApiKeyResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceApiKeyResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {IntegrationServiceApiKeyDataSchema}
     * @memberof IntegrationServiceApiKeyResponseSchema
     */
    'data': IntegrationServiceApiKeyDataSchema;
}
/**
 * 
 * @export
 * @interface IntegrationServiceCreationSchema
 */
export interface IntegrationServiceCreationSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceCreationSchema
     */
    'name': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceDataSchema
 */
export interface IntegrationServiceDataSchema {
    /**
     * 
     * @type {IntegrationServiceOutSchema}
     * @memberof IntegrationServiceDataSchema
     */
    'integration_service': IntegrationServiceOutSchema;
}
/**
 * 
 * @export
 * @interface IntegrationServiceOutSchema
 */
export interface IntegrationServiceOutSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceOutSchema
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceOutSchema
     */
    'api_key': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceResponseSchema
 */
export interface IntegrationServiceResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {IntegrationServiceDataSchema}
     * @memberof IntegrationServiceResponseSchema
     */
    'data': IntegrationServiceDataSchema;
}
/**
 * 
 * @export
 * @interface ListDataEventSchema
 */
export interface ListDataEventSchema {
    /**
     * 
     * @type {any}
     * @memberof ListDataEventSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ListDataFlowSchema
 */
export interface ListDataFlowSchema {
    /**
     * 
     * @type {any}
     * @memberof ListDataFlowSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ListDataFlowTriggerSchema
 */
export interface ListDataFlowTriggerSchema {
    /**
     * 
     * @type {any}
     * @memberof ListDataFlowTriggerSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ListDataNoneType
 */
export interface ListDataNoneType {
    /**
     * 
     * @type {any}
     * @memberof ListDataNoneType
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ListResponseEventSchema
 */
export interface ListResponseEventSchema {
    /**
     * 
     * @type {any}
     * @memberof ListResponseEventSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListResponseEventSchema
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListResponseEventSchema
     */
    'data': any;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ListResponseEventSchema
     */
    'pagination': PaginationSchema;
}
/**
 * 
 * @export
 * @interface ListResponseFlowSchema
 */
export interface ListResponseFlowSchema {
    /**
     * 
     * @type {any}
     * @memberof ListResponseFlowSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListResponseFlowSchema
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListResponseFlowSchema
     */
    'data': any;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ListResponseFlowSchema
     */
    'pagination': PaginationSchema;
}
/**
 * 
 * @export
 * @interface ListResponseFlowTriggerSchema
 */
export interface ListResponseFlowTriggerSchema {
    /**
     * 
     * @type {any}
     * @memberof ListResponseFlowTriggerSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListResponseFlowTriggerSchema
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListResponseFlowTriggerSchema
     */
    'data': any;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ListResponseFlowTriggerSchema
     */
    'pagination': PaginationSchema;
}
/**
 * Pagination data schema.
 * @export
 * @interface PaginationSchema
 */
export interface PaginationSchema {
    /**
     * Total number of elements (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'total_items': any;
    /**
     * Current page (must be at least 1)
     * @type {any}
     * @memberof PaginationSchema
     */
    'page': any;
    /**
     * Number of items per page (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'per_page': any;
    /**
     * Number of the next page (can be None)
     * @type {any}
     * @memberof PaginationSchema
     */
    'next_page'?: any | null;
    /**
     * Number of the previous page (can be None)
     * @type {any}
     * @memberof PaginationSchema
     */
    'prev_page'?: any | null;
    /**
     * Number of total pages (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'total_pages': any;
}
/**
 * 
 * @export
 * @interface Response200
 */
export interface Response200 {
    /**
     * 
     * @type {any}
     * @memberof Response200
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response200
     */
    'message'?: any;
}
/**
 * 400 status response schema.  Bad Request - Bad request syntax or unsupported method
 * @export
 * @interface Response400
 */
export interface Response400 {
    /**
     * 
     * @type {any}
     * @memberof Response400
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response400
     */
    'message'?: any;
}
/**
 * 401 status response schema.  Unauthorized - No permission -- see authorization schemes
 * @export
 * @interface Response401
 */
export interface Response401 {
    /**
     * 
     * @type {any}
     * @memberof Response401
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response401
     */
    'message'?: any;
}
/**
 * 403 status response schema.  Forbidden - Request forbidden -- authorization will not help
 * @export
 * @interface Response403
 */
export interface Response403 {
    /**
     * 
     * @type {any}
     * @memberof Response403
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response403
     */
    'message'?: any;
}
/**
 * 404 status response schema.  Not Found - Nothing matches the given URI
 * @export
 * @interface Response404
 */
export interface Response404 {
    /**
     * 
     * @type {any}
     * @memberof Response404
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response404
     */
    'message'?: any;
}
/**
 * 500 status response schema.  Internal Server Error - Server got itself in trouble
 * @export
 * @interface Response500
 */
export interface Response500 {
    /**
     * 
     * @type {any}
     * @memberof Response500
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response500
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Str
 */
export interface Str {
    /**
     * 
     * @type {any}
     * @memberof Str
     */
    'redirect_url': any;
}
/**
 * 
 * @export
 * @interface UpdateEventSchema
 */
export interface UpdateEventSchema {
    /**
     * 
     * @type {any}
     * @memberof UpdateEventSchema
     */
    'name'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateEventSchema
     */
    'description'?: any | null;
}
/**
 * 
 * @export
 * @interface UpdateFlowTrigger
 */
export interface UpdateFlowTrigger {
    /**
     * 
     * @type {any}
     * @memberof UpdateFlowTrigger
     */
    'event_id'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateFlowTrigger
     */
    'is_enabled'?: any | null;
}
/**
 * Pydantic validation error schema.
 * @export
 * @interface ValidationErrorResponse
 */
export interface ValidationErrorResponse {
    /**
     * 
     * @type {any}
     * @memberof ValidationErrorResponse
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ValidationErrorResponse
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ValidationErrorResponse
     */
    'errors': any;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Renders the login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginPageLoginGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the register page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisterPageRegisterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginLoginPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Renders the login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginPageLoginGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginPageLoginGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the register page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegisterPageRegisterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegisterPageRegisterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginLoginPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseStr>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginLoginPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Renders the login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginPageLoginGet(options?: any): AxiosPromise<any> {
            return localVarFp.getLoginPageLoginGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the register page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisterPageRegisterGet(options?: any): AxiosPromise<any> {
            return localVarFp.getRegisterPageRegisterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginLoginPost(options?: any): AxiosPromise<DataResponseStr> {
            return localVarFp.loginLoginPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Renders the login page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getLoginPageLoginGet(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getLoginPageLoginGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the register page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getRegisterPageRegisterGet(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getRegisterPageRegisterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handle login and set the integration cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginLoginPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginLoginPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Event
         * @param {CreateEventSchema} createEventSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventsPost: async (createEventSchema: CreateEventSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEventSchema' is not null or undefined
            assertParamExists('createEventsPost', 'createEventSchema', createEventSchema)
            const localVarPath = `/events/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Event
         * @param {any} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventsEventIdDelete: async (eventId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteEventsEventIdDelete', 'eventId', eventId)
            const localVarPath = `/events/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all events
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsEventsGet: async (page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Event
         * @param {any} eventId 
         * @param {UpdateEventSchema} updateEventSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventsEventIdPatch: async (eventId: any, updateEventSchema: UpdateEventSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateEventsEventIdPatch', 'eventId', eventId)
            // verify required parameter 'updateEventSchema' is not null or undefined
            assertParamExists('updateEventsEventIdPatch', 'updateEventSchema', updateEventSchema)
            const localVarPath = `/events/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Event
         * @param {CreateEventSchema} createEventSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEventsPost(createEventSchema: CreateEventSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseEventSchemaOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventsPost(createEventSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Event
         * @param {any} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEventsEventIdDelete(eventId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventsEventIdDelete(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all events
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsEventsGet(page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseEventSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsEventsGet(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Event
         * @param {any} eventId 
         * @param {UpdateEventSchema} updateEventSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEventsEventIdPatch(eventId: any, updateEventSchema: UpdateEventSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseEventSchemaOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventsEventIdPatch(eventId, updateEventSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Event
         * @param {CreateEventSchema} createEventSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventsPost(createEventSchema: CreateEventSchema, options?: any): AxiosPromise<DataResponseEventSchemaOut> {
            return localVarFp.createEventsPost(createEventSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Event
         * @param {any} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventsEventIdDelete(eventId: any, options?: any): AxiosPromise<Response200> {
            return localVarFp.deleteEventsEventIdDelete(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all events
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsEventsGet(page?: any, perPage?: any, options?: any): AxiosPromise<ListResponseEventSchema> {
            return localVarFp.getEventsEventsGet(page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Event
         * @param {any} eventId 
         * @param {UpdateEventSchema} updateEventSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventsEventIdPatch(eventId: any, updateEventSchema: UpdateEventSchema, options?: any): AxiosPromise<DataResponseEventSchemaOut> {
            return localVarFp.updateEventsEventIdPatch(eventId, updateEventSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @summary Create Event
     * @param {CreateEventSchema} createEventSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public createEventsPost(createEventSchema: CreateEventSchema, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).createEventsPost(createEventSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Event
     * @param {any} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public deleteEventsEventIdDelete(eventId: any, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).deleteEventsEventIdDelete(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all events
     * @param {any} [page] Current page number
     * @param {any} [perPage] Number of items to get on a page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventsEventsGet(page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventsEventsGet(page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Event
     * @param {any} eventId 
     * @param {UpdateEventSchema} updateEventSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public updateEventsEventIdPatch(eventId: any, updateEventSchema: UpdateEventSchema, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).updateEventsEventIdPatch(eventId, updateEventSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FlowsApi - axios parameter creator
 * @export
 */
export const FlowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all flows
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowsFlowsGet: async (page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/flows/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowsApi - functional programming interface
 * @export
 */
export const FlowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all flows
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowsFlowsGet(page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseFlowSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowsFlowsGet(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlowsApi - factory interface
 * @export
 */
export const FlowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all flows
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowsFlowsGet(page?: any, perPage?: any, options?: any): AxiosPromise<ListResponseFlowSchema> {
            return localVarFp.getFlowsFlowsGet(page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowsApi - object-oriented interface
 * @export
 * @class FlowsApi
 * @extends {BaseAPI}
 */
export class FlowsApi extends BaseAPI {
    /**
     * 
     * @summary Get all flows
     * @param {any} [page] Current page number
     * @param {any} [perPage] Number of items to get on a page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsApi
     */
    public getFlowsFlowsGet(page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return FlowsApiFp(this.configuration).getFlowsFlowsGet(page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FlowsTriggersApi - axios parameter creator
 * @export
 */
export const FlowsTriggersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create flow trigger
         * @param {CreateFlowTrigger} createFlowTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTriggerFlowFlowTriggersPost: async (createFlowTrigger: CreateFlowTrigger, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFlowTrigger' is not null or undefined
            assertParamExists('createTriggerFlowFlowTriggersPost', 'createFlowTrigger', createFlowTrigger)
            const localVarPath = `/flow-triggers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlowTrigger, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete flow trigger
         * @param {any} flowTriggerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTriggerFlowFlowTriggersFlowTriggerIdDelete: async (flowTriggerId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowTriggerId' is not null or undefined
            assertParamExists('deleteTriggerFlowFlowTriggersFlowTriggerIdDelete', 'flowTriggerId', flowTriggerId)
            const localVarPath = `/flow-triggers/{flow_trigger_id}/`
                .replace(`{${"flow_trigger_id"}}`, encodeURIComponent(String(flowTriggerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all trigger flows
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggerFlowsFlowTriggersGet: async (page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/flow-triggers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * All fileds is none required.
         * @summary Update flow trigger
         * @param {any} flowTriggerId 
         * @param {UpdateFlowTrigger} updateFlowTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTriggerFlowFlowTriggersFlowTriggerIdPatch: async (flowTriggerId: any, updateFlowTrigger: UpdateFlowTrigger, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowTriggerId' is not null or undefined
            assertParamExists('updateTriggerFlowFlowTriggersFlowTriggerIdPatch', 'flowTriggerId', flowTriggerId)
            // verify required parameter 'updateFlowTrigger' is not null or undefined
            assertParamExists('updateTriggerFlowFlowTriggersFlowTriggerIdPatch', 'updateFlowTrigger', updateFlowTrigger)
            const localVarPath = `/flow-triggers/{flow_trigger_id}/`
                .replace(`{${"flow_trigger_id"}}`, encodeURIComponent(String(flowTriggerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFlowTrigger, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowsTriggersApi - functional programming interface
 * @export
 */
export const FlowsTriggersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowsTriggersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create flow trigger
         * @param {CreateFlowTrigger} createFlowTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTriggerFlowFlowTriggersPost(createFlowTrigger: CreateFlowTrigger, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseFlowTriggerSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTriggerFlowFlowTriggersPost(createFlowTrigger, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete flow trigger
         * @param {any} flowTriggerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTriggerFlowFlowTriggersFlowTriggerIdDelete(flowTriggerId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTriggerFlowFlowTriggersFlowTriggerIdDelete(flowTriggerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all trigger flows
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTriggerFlowsFlowTriggersGet(page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseFlowTriggerSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTriggerFlowsFlowTriggersGet(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * All fileds is none required.
         * @summary Update flow trigger
         * @param {any} flowTriggerId 
         * @param {UpdateFlowTrigger} updateFlowTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTriggerFlowFlowTriggersFlowTriggerIdPatch(flowTriggerId: any, updateFlowTrigger: UpdateFlowTrigger, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseFlowTriggerSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTriggerFlowFlowTriggersFlowTriggerIdPatch(flowTriggerId, updateFlowTrigger, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlowsTriggersApi - factory interface
 * @export
 */
export const FlowsTriggersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowsTriggersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create flow trigger
         * @param {CreateFlowTrigger} createFlowTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTriggerFlowFlowTriggersPost(createFlowTrigger: CreateFlowTrigger, options?: any): AxiosPromise<DataResponseFlowTriggerSchema> {
            return localVarFp.createTriggerFlowFlowTriggersPost(createFlowTrigger, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete flow trigger
         * @param {any} flowTriggerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTriggerFlowFlowTriggersFlowTriggerIdDelete(flowTriggerId: any, options?: any): AxiosPromise<Response200> {
            return localVarFp.deleteTriggerFlowFlowTriggersFlowTriggerIdDelete(flowTriggerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all trigger flows
         * @param {any} [page] Current page number
         * @param {any} [perPage] Number of items to get on a page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTriggerFlowsFlowTriggersGet(page?: any, perPage?: any, options?: any): AxiosPromise<ListResponseFlowTriggerSchema> {
            return localVarFp.getTriggerFlowsFlowTriggersGet(page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * All fileds is none required.
         * @summary Update flow trigger
         * @param {any} flowTriggerId 
         * @param {UpdateFlowTrigger} updateFlowTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTriggerFlowFlowTriggersFlowTriggerIdPatch(flowTriggerId: any, updateFlowTrigger: UpdateFlowTrigger, options?: any): AxiosPromise<DataResponseFlowTriggerSchema> {
            return localVarFp.updateTriggerFlowFlowTriggersFlowTriggerIdPatch(flowTriggerId, updateFlowTrigger, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowsTriggersApi - object-oriented interface
 * @export
 * @class FlowsTriggersApi
 * @extends {BaseAPI}
 */
export class FlowsTriggersApi extends BaseAPI {
    /**
     * 
     * @summary Create flow trigger
     * @param {CreateFlowTrigger} createFlowTrigger 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsTriggersApi
     */
    public createTriggerFlowFlowTriggersPost(createFlowTrigger: CreateFlowTrigger, options?: AxiosRequestConfig) {
        return FlowsTriggersApiFp(this.configuration).createTriggerFlowFlowTriggersPost(createFlowTrigger, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete flow trigger
     * @param {any} flowTriggerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsTriggersApi
     */
    public deleteTriggerFlowFlowTriggersFlowTriggerIdDelete(flowTriggerId: any, options?: AxiosRequestConfig) {
        return FlowsTriggersApiFp(this.configuration).deleteTriggerFlowFlowTriggersFlowTriggerIdDelete(flowTriggerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all trigger flows
     * @param {any} [page] Current page number
     * @param {any} [perPage] Number of items to get on a page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsTriggersApi
     */
    public getTriggerFlowsFlowTriggersGet(page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return FlowsTriggersApiFp(this.configuration).getTriggerFlowsFlowTriggersGet(page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All fileds is none required.
     * @summary Update flow trigger
     * @param {any} flowTriggerId 
     * @param {UpdateFlowTrigger} updateFlowTrigger 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowsTriggersApi
     */
    public updateTriggerFlowFlowTriggersFlowTriggerIdPatch(flowTriggerId: any, updateFlowTrigger: UpdateFlowTrigger, options?: AxiosRequestConfig) {
        return FlowsTriggersApiFp(this.configuration).updateTriggerFlowFlowTriggersFlowTriggerIdPatch(flowTriggerId, updateFlowTrigger, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FrontApi - axios parameter creator
 * @export
 */
export const FrontApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Renders the home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontApi - functional programming interface
 * @export
 */
export const FrontApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Renders the home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomePageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomePageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontApi - factory interface
 * @export
 */
export const FrontApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontApiFp(configuration)
    return {
        /**
         * 
         * @summary Renders the home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageGet(options?: any): AxiosPromise<any> {
            return localVarFp.getHomePageGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontApi - object-oriented interface
 * @export
 * @class FrontApi
 * @extends {BaseAPI}
 */
export class FrontApi extends BaseAPI {
    /**
     * 
     * @summary Renders the home page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getHomePageGet(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getHomePageGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzHealthzGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthz`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthzHealthzGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthzHealthzGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary Healthz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzHealthzGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthzHealthzGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary Healthz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public healthzHealthzGet(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).healthzHealthzGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NodeRedAuthApi - axios parameter creator
 * @export
 */
export const NodeRedAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Renders the Node Red login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeRedLoginPageNodeRedLoginGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/node-red/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginNodeRedLoginPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/node-red/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeRedAuthApi - functional programming interface
 * @export
 */
export const NodeRedAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeRedAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Renders the Node Red login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodeRedLoginPageNodeRedLoginGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeRedLoginPageNodeRedLoginGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginNodeRedLoginPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseStr>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginNodeRedLoginPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeRedAuthApi - factory interface
 * @export
 */
export const NodeRedAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeRedAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Renders the Node Red login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeRedLoginPageNodeRedLoginGet(options?: any): AxiosPromise<any> {
            return localVarFp.getNodeRedLoginPageNodeRedLoginGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginNodeRedLoginPost(options?: any): AxiosPromise<DataResponseStr> {
            return localVarFp.loginNodeRedLoginPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeRedAuthApi - object-oriented interface
 * @export
 * @class NodeRedAuthApi
 * @extends {BaseAPI}
 */
export class NodeRedAuthApi extends BaseAPI {
    /**
     * 
     * @summary Renders the Node Red login page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeRedAuthApi
     */
    public getNodeRedLoginPageNodeRedLoginGet(options?: AxiosRequestConfig) {
        return NodeRedAuthApiFp(this.configuration).getNodeRedLoginPageNodeRedLoginGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handle login and set the integration cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeRedAuthApi
     */
    public loginNodeRedLoginPost(options?: AxiosRequestConfig) {
        return NodeRedAuthApiFp(this.configuration).loginNodeRedLoginPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServiceIntegrationApi - axios parameter creator
 * @export
 */
export const ServiceIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create API key for integration service
         * @param {any} [expiresAt] Integration service API key expiration time (seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKeyIntegrationServicesKeysPost: async (expiresAt?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/services/keys/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)

            if (expiresAt !== undefined) {
                localVarQueryParameter['expires_at'] = expiresAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create integration service and API key
         * @param {IntegrationServiceCreationSchema} integrationServiceCreationSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegrationServiceIntegrationServicesPost: async (integrationServiceCreationSchema: IntegrationServiceCreationSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationServiceCreationSchema' is not null or undefined
            assertParamExists('createIntegrationServiceIntegrationServicesPost', 'integrationServiceCreationSchema', integrationServiceCreationSchema)
            const localVarPath = `/integration/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationServiceCreationSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate integration API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateApiKeyIntegrationKeysDeactivatePatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/keys/deactivate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete integration service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationServiceIntegrationServicesDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore API key for an integration service
         * @param {any} serviceName The name of the integration service to restore the api key for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreKeyIntegrationRestoreKeyPost: async (serviceName: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('restoreKeyIntegrationRestoreKeyPost', 'serviceName', serviceName)
            const localVarPath = `/integration/restore-key/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Restore secret required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Restore-Secret", configuration)

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceIntegrationApi - functional programming interface
 * @export
 */
export const ServiceIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create API key for integration service
         * @param {any} [expiresAt] Integration service API key expiration time (seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApiKeyIntegrationServicesKeysPost(expiresAt?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationServiceApiKeyResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApiKeyIntegrationServicesKeysPost(expiresAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create integration service and API key
         * @param {IntegrationServiceCreationSchema} integrationServiceCreationSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIntegrationServiceIntegrationServicesPost(integrationServiceCreationSchema: IntegrationServiceCreationSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationServiceResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIntegrationServiceIntegrationServicesPost(integrationServiceCreationSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate integration API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateApiKeyIntegrationKeysDeactivatePatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateApiKeyIntegrationKeysDeactivatePatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete integration service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIntegrationServiceIntegrationServicesDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIntegrationServiceIntegrationServicesDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore API key for an integration service
         * @param {any} serviceName The name of the integration service to restore the api key for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreKeyIntegrationRestoreKeyPost(serviceName: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationServiceApiKeyResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreKeyIntegrationRestoreKeyPost(serviceName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceIntegrationApi - factory interface
 * @export
 */
export const ServiceIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create API key for integration service
         * @param {any} [expiresAt] Integration service API key expiration time (seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKeyIntegrationServicesKeysPost(expiresAt?: any, options?: any): AxiosPromise<IntegrationServiceApiKeyResponseSchema> {
            return localVarFp.createApiKeyIntegrationServicesKeysPost(expiresAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create integration service and API key
         * @param {IntegrationServiceCreationSchema} integrationServiceCreationSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegrationServiceIntegrationServicesPost(integrationServiceCreationSchema: IntegrationServiceCreationSchema, options?: any): AxiosPromise<IntegrationServiceResponseSchema> {
            return localVarFp.createIntegrationServiceIntegrationServicesPost(integrationServiceCreationSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate integration API key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateApiKeyIntegrationKeysDeactivatePatch(options?: any): AxiosPromise<Response200> {
            return localVarFp.deactivateApiKeyIntegrationKeysDeactivatePatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete integration service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationServiceIntegrationServicesDelete(options?: any): AxiosPromise<Response200> {
            return localVarFp.deleteIntegrationServiceIntegrationServicesDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore API key for an integration service
         * @param {any} serviceName The name of the integration service to restore the api key for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreKeyIntegrationRestoreKeyPost(serviceName: any, options?: any): AxiosPromise<IntegrationServiceApiKeyResponseSchema> {
            return localVarFp.restoreKeyIntegrationRestoreKeyPost(serviceName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceIntegrationApi - object-oriented interface
 * @export
 * @class ServiceIntegrationApi
 * @extends {BaseAPI}
 */
export class ServiceIntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Create API key for integration service
     * @param {any} [expiresAt] Integration service API key expiration time (seconds)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public createApiKeyIntegrationServicesKeysPost(expiresAt?: any, options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).createApiKeyIntegrationServicesKeysPost(expiresAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create integration service and API key
     * @param {IntegrationServiceCreationSchema} integrationServiceCreationSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public createIntegrationServiceIntegrationServicesPost(integrationServiceCreationSchema: IntegrationServiceCreationSchema, options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).createIntegrationServiceIntegrationServicesPost(integrationServiceCreationSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate integration API key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public deactivateApiKeyIntegrationKeysDeactivatePatch(options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).deactivateApiKeyIntegrationKeysDeactivatePatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete integration service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public deleteIntegrationServiceIntegrationServicesDelete(options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).deleteIntegrationServiceIntegrationServicesDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore API key for an integration service
     * @param {any} serviceName The name of the integration service to restore the api key for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public restoreKeyIntegrationRestoreKeyPost(serviceName: any, options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).restoreKeyIntegrationRestoreKeyPost(serviceName, options).then((request) => request(this.axios, this.basePath));
    }
}



