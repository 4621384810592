import {IBaseStateField, IResponse} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getNewStateByStatus} from '@/utils/getNewStateByStatus'

import {
    ICreateApiKeyIntegrationServices,
    ICreateApiKeyIntegrationServicesData,
} from '@/store/actionTypes/integrationKeys'
import {
    createApiKeyIntegrationServices,
    deactivateApiKeyIntegration, deleteApiKeyIntegration,
} from '@/store/entities/integrationKeys/actions'
import {IntegrationService} from '@/api/IntegrationService'

interface IIntegrationKeysState {
    createApiKeyIntegrationServicesState: IBaseStateField
    createApiKeyIntegrationServicesData: ICreateApiKeyIntegrationServices | null
    deactivateApiKeyIntegrationState: IBaseStateField
    deleteApiKeyIntegrationState: IBaseStateField
}

const initialState: IIntegrationKeysState = {
    createApiKeyIntegrationServicesState: getNewStateByStatus('base'),
    createApiKeyIntegrationServicesData: null,
    deactivateApiKeyIntegrationState: getNewStateByStatus('base'),
    deleteApiKeyIntegrationState: getNewStateByStatus('base')
}

export const integrationKeysSlice = createSlice({
    name: 'integrationKeys',
    initialState,
    reducers: {
        resetIntegrationKeysStates: (state) => {
            state.createApiKeyIntegrationServicesState = getNewStateByStatus('base')
            state.deactivateApiKeyIntegrationState = getNewStateByStatus('base')
            state.deleteApiKeyIntegrationState = getNewStateByStatus('base')
        }
    },
    extraReducers: {
        [createApiKeyIntegrationServices.pending.type]: (state) => {
            state.createApiKeyIntegrationServicesState = getNewStateByStatus('pending')
        },
        [createApiKeyIntegrationServices.fulfilled.type]: (state, action: PayloadAction<IResponse<ICreateApiKeyIntegrationServicesData>>) => {
            state.createApiKeyIntegrationServicesState = getNewStateByStatus('fulfilled')
            state.createApiKeyIntegrationServicesData = action.payload.data.api_key
        },
        [createApiKeyIntegrationServices.rejected.type]: (state)  => {
            state.createApiKeyIntegrationServicesState = getNewStateByStatus('rejected')
        },

        [deactivateApiKeyIntegration.pending.type]: (state) => {
            state.deactivateApiKeyIntegrationState = getNewStateByStatus('pending')
        },
        [deactivateApiKeyIntegration.fulfilled.type]: (state) => {
            state.deactivateApiKeyIntegrationState = getNewStateByStatus('fulfilled')
            IntegrationService.removeIntegrationToken()
        },
        [deactivateApiKeyIntegration.rejected.type]: (state)  => {
            state.deactivateApiKeyIntegrationState = getNewStateByStatus('rejected')
        },

        [deleteApiKeyIntegration.pending.type]: (state) => {
            state.deleteApiKeyIntegrationState = getNewStateByStatus('pending')
        },
        [deleteApiKeyIntegration.fulfilled.type]: (state) => {
            state.deleteApiKeyIntegrationState = getNewStateByStatus('fulfilled')
            IntegrationService.removeIntegrationToken()
        },
        [deleteApiKeyIntegration.rejected.type]: (state)  => {
            state.deleteApiKeyIntegrationState = getNewStateByStatus('rejected')
        }
    }
})
