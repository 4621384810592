import {IPaginationData} from '@/types/type'

import {createAsyncThunk} from '@reduxjs/toolkit'

import {Api} from '@/api/apiService'

import {requestHandle} from '@/utils/requestHandle'
import {IFlowTrigger} from '@/store/actionTypes/flowsTriggers'

export const getFlowsTriggers = createAsyncThunk(
    'flowsTriggers/getFlowsTriggers',
    async (data: IPaginationData, thunkAPI) => {
        const response = await requestHandle(() => Api.FlowsTriggers.getTriggerFlowsFlowTriggersGet(data.page, data.perPage), thunkAPI)
        return response
    }
)

export const createFlowTrigger = createAsyncThunk(
    'flowsTriggers/createFlowTrigger',
    async (data: Omit<IFlowTrigger, 'id'>, thunkAPI) => {
        const response = await requestHandle(() => Api.FlowsTriggers.createTriggerFlowFlowTriggersPost(data), thunkAPI)
        return response
    }
)

export const editFlowTrigger = createAsyncThunk(
    'flowsTriggers/editFlowTrigger',
    async (data: Omit<IFlowTrigger, 'flow_id'>, thunkAPI) => {
        const response = await requestHandle(() => Api.FlowsTriggers.updateTriggerFlowFlowTriggersFlowTriggerIdPatch(data.id, data), thunkAPI)
        return response
    }
)

export const deleteFlowTrigger = createAsyncThunk(
    'flowsTriggers/deleteFlowTrigger',
    async (data: Pick<IFlowTrigger, 'id'>, thunkAPI) => {
        const response = await requestHandle(() => Api.FlowsTriggers.deleteTriggerFlowFlowTriggersFlowTriggerIdDelete(data.id), thunkAPI)
        return response
    }
)
