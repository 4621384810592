// import {authActions} from './entities/auth'

import {eventsActions} from '@/store/entities/events'
import {flowsActions} from '@/store/entities/flows'
import {flowsTriggersActions} from '@/store/entities/flowsTriggers'
import {integrationActions} from "@/store/entities/integration";
import {integrationKeysActions} from '@/store/entities/integrationKeys'
import {oauthActions} from "@/store/entities/oauth";
import {oauthCredentialsActions} from "@/store/entities/oauthCredentials";

export default {
    ...integrationActions,
    ...oauthActions,
    ...oauthCredentialsActions,
    ...integrationKeysActions,
    ...eventsActions,
    ...flowsActions,
    ...flowsTriggersActions,
}
