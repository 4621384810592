import {FC, useLayoutEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'

import {AppFonts} from '@/utils/AppFonts'
import MantineProviders from '@/utils/MantineProviders'

import AppRouter from '@/routes/AppRouter'

import '@/utils/i18n'

import {api} from "@/index";
import {Container} from '@mantine/core'

const App: FC = () => {
    useLayoutEffect(() => {
        api.init()
    }, [])

    return (
        <MantineProviders>
            <AppFonts />
            <BrowserRouter>
                <Container mih={'100vh'} pt={30}>
                    <AppRouter />
                </Container>
            </BrowserRouter>
        </MantineProviders>
    )
}

export default App
