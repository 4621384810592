import {IBaseStateField} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getNewStateByStatus} from '@/utils/getNewStateByStatus'

import {
    IChoseFlowTrigger,
    IGetFlowsTriggersReturn,
} from '@/store/actionTypes/flowsTriggers'
import {
    createFlowTrigger, deleteFlowTrigger,
    editFlowTrigger,
    getFlowsTriggers
} from '@/store/entities/flowsTriggers/actions'

interface IState {
    getFlowsTriggersState: IBaseStateField
    createFlowTriggerState: IBaseStateField
    editFlowTriggerState: IBaseStateField,
    deleteFlowTriggerState: IBaseStateField,
    flowsTriggers: IGetFlowsTriggersReturn | null
    choseFlowTrigger: IChoseFlowTrigger | null
}

const initialState: IState = {
    getFlowsTriggersState: getNewStateByStatus('base'),
    createFlowTriggerState: getNewStateByStatus('base'),
    editFlowTriggerState: getNewStateByStatus('base'),
    deleteFlowTriggerState: getNewStateByStatus('base'),
    choseFlowTrigger: null,
    flowsTriggers: null,
}

export const flowsTriggersSlice = createSlice({
    name: "flowsTriggers",
    initialState,
    reducers: {
        resetCreateFlowTriggerState: (state) => {
            state.createFlowTriggerState = getNewStateByStatus('base')
        },
        resetEditFlowTriggerState: (state) => {
            state.editFlowTriggerState = getNewStateByStatus('base')
        },
        resetDeleteFlowTriggerState: (state) => {
            state.deleteFlowTriggerState = getNewStateByStatus('base')
        },
        setChoseFlowTrigger: (state, action: PayloadAction<IChoseFlowTrigger | null>) => {
            state.choseFlowTrigger = action.payload
        }
    },
    extraReducers: {
        [getFlowsTriggers.pending.type]: (state) => {
            state.getFlowsTriggersState = getNewStateByStatus('pending')
        },
        [getFlowsTriggers.fulfilled.type]: (state, action: PayloadAction<IGetFlowsTriggersReturn>) => {
            state.getFlowsTriggersState = getNewStateByStatus('fulfilled')
            state.flowsTriggers = action.payload
        },
        [getFlowsTriggers.rejected.type]: (state) => {
            state.getFlowsTriggersState = getNewStateByStatus('rejected')
        },
        [createFlowTrigger.pending.type]: (state) => {
            state.createFlowTriggerState = getNewStateByStatus('pending')
        },
        [createFlowTrigger.fulfilled.type]: (state) => {
            state.createFlowTriggerState = getNewStateByStatus('fulfilled')
        },
        [createFlowTrigger.rejected.type]: (state) => {
            state.createFlowTriggerState = getNewStateByStatus('rejected')
        },
        [editFlowTrigger.pending.type]: (state) => {
            state.editFlowTriggerState = getNewStateByStatus('pending')
        },
        [editFlowTrigger.fulfilled.type]: (state) => {
            state.editFlowTriggerState = getNewStateByStatus('fulfilled')
        },
        [editFlowTrigger.rejected.type]: (state) => {
            state.editFlowTriggerState = getNewStateByStatus('rejected')
        },
        [deleteFlowTrigger.pending.type]: (state) => {
            state.deleteFlowTriggerState = getNewStateByStatus('pending')
        },
        [deleteFlowTrigger.fulfilled.type]: (state) => {
            state.deleteFlowTriggerState = getNewStateByStatus('fulfilled')
        },
        [deleteFlowTrigger.rejected.type]: (state) => {
            state.deleteFlowTriggerState = getNewStateByStatus('rejected')
        },
    }
})
