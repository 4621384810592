import {IPaginationData} from '@/types/type'

import {createAsyncThunk} from '@reduxjs/toolkit'

import {Api} from '@/api/apiService'

import {requestHandle} from '@/utils/requestHandle'

export const getFlows = createAsyncThunk(
    'flows/getFlows',
    async (data: IPaginationData, thunkAPI) => {
        const response = await requestHandle(() => Api.Flows.getFlowsFlowsGet(data.page, data.perPage), thunkAPI)
        return response
    }
)
