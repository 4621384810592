import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'

import {Api} from './api/apiService'
import App from './App'
import {store} from './store'

export const api: Api = new Api()

const root = ReactDOM.createRoot(document.getElementById('root') as Element)

root.render(
    // @ts-ignore
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
)
