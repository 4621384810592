import {IBaseStateField} from '@/types/type'

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getNewStateByStatus} from '@/utils/getNewStateByStatus'

import {IChoseEvent, IEvent, IGetEventsReturn} from '@/store/actionTypes/events'
import {createEvent, deleteEvent, editEvent, getEvents} from '@/store/entities/events/actions'

interface IState {
    getEventsState: IBaseStateField
    createEventState: IBaseStateField
    deleteEventState: IBaseStateField
    editEventState: IBaseStateField
    events: IGetEventsReturn | null
    choseEvent: IChoseEvent | null
}

const initialState: IState = {
    getEventsState: getNewStateByStatus('base'),
    createEventState: getNewStateByStatus('base'),
    deleteEventState: getNewStateByStatus('base'),
    editEventState: getNewStateByStatus('base'),
    choseEvent: null,
    events: null
}

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        resetGetEventsState: (state) => {
            state.getEventsState = getNewStateByStatus('base')
        },
        resetCreateEventState: (state) => {
            state.createEventState = getNewStateByStatus('base')
        },
        resetDeleteEventState: (state) => {
            state.deleteEventState = getNewStateByStatus('base')
        },
        resetEditEventState: (state) => {
            state.editEventState = getNewStateByStatus('base')
        },
        setChoseEvent: (state, action: PayloadAction<IChoseEvent | null>) => {
            state.choseEvent = action.payload
        }
    },
    extraReducers: {
        [getEvents.pending.type]: (state) => {
            state.getEventsState = getNewStateByStatus('pending')
        },
        [getEvents.fulfilled.type]: (state, action: PayloadAction<IGetEventsReturn>) => {
            state.getEventsState = getNewStateByStatus('fulfilled')
            state.events = action.payload
        },
        [getEvents.rejected.type]: (state) => {
            state.getEventsState = getNewStateByStatus('rejected')
        },
        [createEvent.pending.type]: (state) => {
            state.createEventState = getNewStateByStatus('pending')
        },
        [createEvent.fulfilled.type]: (state) => {
            state.createEventState = getNewStateByStatus('fulfilled')
        },
        [createEvent.rejected.type]: (state) => {
            state.createEventState = getNewStateByStatus('rejected')
        },
        [deleteEvent.pending.type]: (state) => {
            state.deleteEventState = getNewStateByStatus('pending')
        },
        [deleteEvent.fulfilled.type]: (state) => {
            state.deleteEventState = getNewStateByStatus('fulfilled')
        },
        [deleteEvent.rejected.type]: (state) => {
            state.deleteEventState = getNewStateByStatus('rejected')
        },
        [editEvent.pending.type]: (state) => {
            state.editEventState = getNewStateByStatus('pending')
        },
        [editEvent.fulfilled.type]: (state) => {
            state.editEventState = getNewStateByStatus('fulfilled')
        },
        [editEvent.rejected.type]: (state) => {
            state.editEventState = getNewStateByStatus('rejected')
        },
    }
})
