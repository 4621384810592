/* tslint:disable */
/* eslint-disable */
/**
 * Auth0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AppsApiSchemasServiceSettingsServiceSettingsOutScheme
 */
export interface AppsApiSchemasServiceSettingsServiceSettingsOutScheme {
    /**
     * 
     * @type {any}
     * @memberof AppsApiSchemasServiceSettingsServiceSettingsOutScheme
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiSchemasServiceSettingsServiceSettingsOutScheme
     */
    'service_id': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiSchemasServiceSettingsServiceSettingsOutScheme
     */
    'jwt_secret': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiSchemasServiceSettingsServiceSettingsOutScheme
     */
    'login_redirect_uri': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiSchemasServiceSettingsServiceSettingsOutScheme
     */
    'is_email_login_enabled': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiSchemasServiceSettingsServiceSettingsOutScheme
     */
    'is_phone_login_enabled': any;
}
/**
 * 
 * @export
 * @interface BasicUser
 */
export interface BasicUser {
    /**
     * User\'s phone number
     * @type {any}
     * @memberof BasicUser
     */
    'phone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof BasicUser
     */
    'email'?: any | null;
    /**
     * User\'s email verification status
     * @type {any}
     * @memberof BasicUser
     */
    'email_verified'?: any;
    /**
     * User\'s phone verification status
     * @type {any}
     * @memberof BasicUser
     */
    'phone_verified'?: any;
    /**
     * User\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof BasicUser
     */
    'role_id'?: any | null;
    /**
     * ID of integration service the user belongs to
     * @type {any}
     * @memberof BasicUser
     */
    'service_id': any;
}
/**
 * 
 * @export
 * @interface ClientInfoResponse
 */
export interface ClientInfoResponse {
    /**
     * 
     * @type {any}
     * @memberof ClientInfoResponse
     */
    'success'?: any;
    /**
     * 
     * @type {BasicUser}
     * @memberof ClientInfoResponse
     */
    'result': BasicUser;
}
/**
 * 
 * @export
 * @interface ConfirmationCodeResponseScheme
 */
export interface ConfirmationCodeResponseScheme {
    /**
     * 
     * @type {any}
     * @memberof ConfirmationCodeResponseScheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ConfirmationCodeResponseScheme
     */
    'message'?: any;
    /**
     * 
     * @type {ResetCodeSchemaOut}
     * @memberof ConfirmationCodeResponseScheme
     */
    'result': ResetCodeSchemaOut;
}
/**
 * 
 * @export
 * @interface CreateIntegrationServiceSchema
 */
export interface CreateIntegrationServiceSchema {
    /**
     * 
     * @type {any}
     * @memberof CreateIntegrationServiceSchema
     */
    'name': any;
}
/**
 * 
 * @export
 * @interface CreateUserScheme
 */
export interface CreateUserScheme {
    /**
     * User\'s phone number
     * @type {any}
     * @memberof CreateUserScheme
     */
    'phone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof CreateUserScheme
     */
    'email'?: any | null;
    /**
     * User\'s email verification status
     * @type {any}
     * @memberof CreateUserScheme
     */
    'email_verified'?: any;
    /**
     * User\'s phone verification status
     * @type {any}
     * @memberof CreateUserScheme
     */
    'phone_verified'?: any;
    /**
     * User\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof CreateUserScheme
     */
    'role_id'?: any | null;
    /**
     * ID of integration service the user belongs to
     * @type {any}
     * @memberof CreateUserScheme
     */
    'service_id': any;
    /**
     * User\'s deleted status: delete or not
     * @type {any}
     * @memberof CreateUserScheme
     */
    'deleted_at'?: any | null;
    /**
     * User\'s created status: delete or not
     * @type {any}
     * @memberof CreateUserScheme
     */
    'created_at'?: any | null;
    /**
     * User\'s updated status: delete or not
     * @type {any}
     * @memberof CreateUserScheme
     */
    'updated_at'?: any | null;
    /**
     * User\'s password
     * @type {any}
     * @memberof CreateUserScheme
     */
    'password'?: any | null;
}
/**
 * 
 * @export
 * @interface DataResponseServiceSettingsOutScheme
 */
export interface DataResponseServiceSettingsOutScheme {
    /**
     * 
     * @type {any}
     * @memberof DataResponseServiceSettingsOutScheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof DataResponseServiceSettingsOutScheme
     */
    'message'?: any;
    /**
     * 
     * @type {FastapiScaffoldResponsesServiceSettingsOutScheme}
     * @memberof DataResponseServiceSettingsOutScheme
     */
    'data': FastapiScaffoldResponsesServiceSettingsOutScheme;
}
/**
 * 
 * @export
 * @interface EditUserScheme
 */
export interface EditUserScheme {
    /**
     * User\'s phone number
     * @type {any}
     * @memberof EditUserScheme
     */
    'phone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof EditUserScheme
     */
    'email'?: any | null;
    /**
     * User\'s email verification status
     * @type {any}
     * @memberof EditUserScheme
     */
    'email_verified'?: any;
    /**
     * User\'s phone verification status
     * @type {any}
     * @memberof EditUserScheme
     */
    'phone_verified'?: any;
    /**
     * User\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof EditUserScheme
     */
    'role_id'?: any | null;
    /**
     * ID of integration service the user belongs to
     * @type {any}
     * @memberof EditUserScheme
     */
    'service_id': any;
}
/**
 * 
 * @export
 * @interface EmailConfirmationOutScheme
 */
export interface EmailConfirmationOutScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailConfirmationOutScheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof EmailConfirmationOutScheme
     */
    'message'?: any;
    /**
     * 
     * @type {EmailConfirmationScheme}
     * @memberof EmailConfirmationOutScheme
     */
    'result': EmailConfirmationScheme;
}
/**
 * 
 * @export
 * @interface EmailConfirmationScheme
 */
export interface EmailConfirmationScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailConfirmationScheme
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof EmailConfirmationScheme
     */
    'email_verified': any;
}
/**
 * 
 * @export
 * @interface EmailLoginScheme
 */
export interface EmailLoginScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailLoginScheme
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof EmailLoginScheme
     */
    'password': any;
}
/**
 * 
 * @export
 * @interface EmailRecoverySchema
 */
export interface EmailRecoverySchema {
    /**
     * 
     * @type {any}
     * @memberof EmailRecoverySchema
     */
    'email': any;
}
/**
 * 
 * @export
 * @interface EmailRegisterScheme
 */
export interface EmailRegisterScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailRegisterScheme
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof EmailRegisterScheme
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof EmailRegisterScheme
     */
    'repeat_password': any;
}
/**
 * 
 * @export
 * @interface EmailResendCodeScheme
 */
export interface EmailResendCodeScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailResendCodeScheme
     */
    'email': any;
}
/**
 * 
 * @export
 * @interface ExceptionScheme
 */
export interface ExceptionScheme {
    /**
     * 
     * @type {any}
     * @memberof ExceptionScheme
     */
    'detail': any;
}
/**
 * 
 * @export
 * @interface FastapiScaffoldResponsesServiceSettingsOutScheme
 */
export interface FastapiScaffoldResponsesServiceSettingsOutScheme {
    /**
     * 
     * @type {AppsApiSchemasServiceSettingsServiceSettingsOutScheme}
     * @memberof FastapiScaffoldResponsesServiceSettingsOutScheme
     */
    'settings': AppsApiSchemasServiceSettingsServiceSettingsOutScheme;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {any}
     * @memberof HTTPValidationError
     */
    'detail'?: any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceAPIKeyListOutSchema
 */
export interface IntegrationServiceAPIKeyListOutSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceAPIKeyListOutSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceAPIKeyListResponseSchema
 */
export interface IntegrationServiceAPIKeyListResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceAPIKeyListResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceAPIKeyListResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {IntegrationServiceAPIKeyListOutSchema}
     * @memberof IntegrationServiceAPIKeyListResponseSchema
     */
    'data': IntegrationServiceAPIKeyListOutSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof IntegrationServiceAPIKeyListResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface IntegrationServiceApiKeyDataSchema
 */
export interface IntegrationServiceApiKeyDataSchema {
    /**
     * 
     * @type {IntegrationServiceApiKeyOutSchema}
     * @memberof IntegrationServiceApiKeyDataSchema
     */
    'api_key': IntegrationServiceApiKeyOutSchema;
}
/**
 * 
 * @export
 * @interface IntegrationServiceApiKeyOutSchema
 */
export interface IntegrationServiceApiKeyOutSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceApiKeyOutSchema
     */
    'api_key': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceApiKeyResponseSchema
 */
export interface IntegrationServiceApiKeyResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceApiKeyResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceApiKeyResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {IntegrationServiceApiKeyDataSchema}
     * @memberof IntegrationServiceApiKeyResponseSchema
     */
    'data': IntegrationServiceApiKeyDataSchema;
}
/**
 * 
 * @export
 * @interface IntegrationServiceDataSchema
 */
export interface IntegrationServiceDataSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceDataSchema
     */
    'integration_service': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceInfoOutSchema
 */
export interface IntegrationServiceInfoOutSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceInfoOutSchema
     */
    'name': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceOutSchema
 */
export interface IntegrationServiceOutSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceOutSchema
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceOutSchema
     */
    'api_key': any;
}
/**
 * 
 * @export
 * @interface IntegrationServiceResponseSchema
 */
export interface IntegrationServiceResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof IntegrationServiceResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {IntegrationServiceDataSchema}
     * @memberof IntegrationServiceResponseSchema
     */
    'data': IntegrationServiceDataSchema;
}
/**
 * 
 * @export
 * @interface JWTScheme
 */
export interface JWTScheme {
    /**
     * 
     * @type {any}
     * @memberof JWTScheme
     */
    'auth_token': any;
    /**
     * 
     * @type {any}
     * @memberof JWTScheme
     */
    'refresh_token': any;
}
/**
 * 
 * @export
 * @interface OAuthCredentialsScheme
 */
export interface OAuthCredentialsScheme {
    /**
     * 
     * @type {OAuthProvider}
     * @memberof OAuthCredentialsScheme
     */
    'provider': OAuthProvider;
    /**
     * 
     * @type {any}
     * @memberof OAuthCredentialsScheme
     */
    'client_id': any;
    /**
     * 
     * @type {any}
     * @memberof OAuthCredentialsScheme
     */
    'client_secret': any | null;
    /**
     * 
     * @type {any}
     * @memberof OAuthCredentialsScheme
     */
    'is_active'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof OAuthCredentialsScheme
     */
    'priority'?: any;
    /**
     * 
     * @type {any}
     * @memberof OAuthCredentialsScheme
     */
    'additional_data': any | null;
}


/**
 * 
 * @export
 * @interface OAuthProvider
 */
export interface OAuthProvider {
}
/**
 * 
 * @export
 * @interface PageUserScheme
 */
export interface PageUserScheme {
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'items': any;
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'total': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'page': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'size': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'pages'?: any | null;
}
/**
 * Pagination data scheme  Attributes:     total_items (int): total number of elements     page (int): current page     items_per_page (int): number of items per page     next_page (int | None): number of the next page     prev_page (int | None): number of the previous page     total_pages (int): number of total pages
 * @export
 * @interface PaginationSchema
 */
export interface PaginationSchema {
    /**
     * Total number of elements (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'total_items': any;
    /**
     * Current page (must be at least 1)
     * @type {any}
     * @memberof PaginationSchema
     */
    'page': any;
    /**
     * Number of items per page (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'items_per_page': any;
    /**
     * Number of the next page (can be None)
     * @type {any}
     * @memberof PaginationSchema
     */
    'next_page'?: any | null;
    /**
     * Number of the previous page (can be None)
     * @type {any}
     * @memberof PaginationSchema
     */
    'prev_page'?: any | null;
    /**
     * Number of total pages (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'total_pages': any;
}
/**
 * 
 * @export
 * @interface PasswordSchema
 */
export interface PasswordSchema {
    /**
     * 
     * @type {any}
     * @memberof PasswordSchema
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof PasswordSchema
     */
    'repeat_password': any;
}
/**
 * 
 * @export
 * @interface PhoneCodeLoginScheme
 */
export interface PhoneCodeLoginScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneCodeLoginScheme
     */
    'phone_number': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneCodeLoginScheme
     */
    'code': any;
}
/**
 * 
 * @export
 * @interface PhoneCodeScheme
 */
export interface PhoneCodeScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneCodeScheme
     */
    'code': any;
}
/**
 * 
 * @export
 * @interface PhoneConfirmationOutScheme
 */
export interface PhoneConfirmationOutScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneConfirmationOutScheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof PhoneConfirmationOutScheme
     */
    'message'?: any;
    /**
     * 
     * @type {PhoneConfirmationScheme}
     * @memberof PhoneConfirmationOutScheme
     */
    'result': PhoneConfirmationScheme;
}
/**
 * 
 * @export
 * @interface PhoneConfirmationScheme
 */
export interface PhoneConfirmationScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneConfirmationScheme
     */
    'phone': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneConfirmationScheme
     */
    'phone_verified': any;
}
/**
 * 
 * @export
 * @interface PhoneLoginScheme
 */
export interface PhoneLoginScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneLoginScheme
     */
    'phone_number': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneLoginScheme
     */
    'password': any;
}
/**
 * 
 * @export
 * @interface PhoneRecoverySchema
 */
export interface PhoneRecoverySchema {
    /**
     * 
     * @type {any}
     * @memberof PhoneRecoverySchema
     */
    'phone': any;
}
/**
 * 
 * @export
 * @interface PhoneRegisterScheme
 */
export interface PhoneRegisterScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneRegisterScheme
     */
    'phone': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneRegisterScheme
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneRegisterScheme
     */
    'repeat_password': any;
}
/**
 * 
 * @export
 * @interface PhoneResendCodeScheme
 */
export interface PhoneResendCodeScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneResendCodeScheme
     */
    'phone': any;
}
/**
 * 
 * @export
 * @interface ResetCodeSchemaOut
 */
export interface ResetCodeSchemaOut {
    /**
     * 
     * @type {any}
     * @memberof ResetCodeSchemaOut
     */
    'expired_at': any;
}
/**
 * 
 * @export
 * @interface Response200Scheme
 */
export interface Response200Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response200Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response200Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response400Scheme
 */
export interface Response400Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response400Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response400Scheme
     */
    'message'?: any;
}
/**
 * 401 status response schema.  Unauthorized - No permission -- see authorization schemes
 * @export
 * @interface Response401
 */
export interface Response401 {
    /**
     * 
     * @type {any}
     * @memberof Response401
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response401
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response401Scheme
 */
export interface Response401Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response401Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response401Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response403Scheme
 */
export interface Response403Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response403Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response403Scheme
     */
    'message'?: any;
}
/**
 * 404 status response schema.  Not Found - Nothing matches the given URI
 * @export
 * @interface Response404
 */
export interface Response404 {
    /**
     * 
     * @type {any}
     * @memberof Response404
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response404
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response404Scheme
 */
export interface Response404Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response404Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response404Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response422Scheme
 */
export interface Response422Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response422Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response422Scheme
     */
    'message'?: any;
}
/**
 * 500 status response schema.  Internal Server Error - Server got itself in trouble
 * @export
 * @interface Response500
 */
export interface Response500 {
    /**
     * 
     * @type {any}
     * @memberof Response500
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response500
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response500Scheme
 */
export interface Response500Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response500Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response500Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface SessionSchema
 */
export interface SessionSchema {
    /**
     * 
     * @type {any}
     * @memberof SessionSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof SessionSchema
     */
    'user_id': any;
    /**
     * 
     * @type {any}
     * @memberof SessionSchema
     */
    'ip': any;
    /**
     * 
     * @type {any}
     * @memberof SessionSchema
     */
    'is_active'?: any;
}
/**
 * 
 * @export
 * @interface SessionsListSchema
 */
export interface SessionsListSchema {
    /**
     * 
     * @type {any}
     * @memberof SessionsListSchema
     */
    'sessions': any;
}
/**
 * 
 * @export
 * @interface UpdateServiceSettingsScheme
 */
export interface UpdateServiceSettingsScheme {
    /**
     * 
     * @type {any}
     * @memberof UpdateServiceSettingsScheme
     */
    'jwt_secret'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateServiceSettingsScheme
     */
    'login_redirect_uri'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateServiceSettingsScheme
     */
    'is_email_login_enabled'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateServiceSettingsScheme
     */
    'is_phone_login_enabled'?: any | null;
}
/**
 * 
 * @export
 * @interface UserFilterScheme
 */
export interface UserFilterScheme {
    /**
     * Filter by phone number
     * @type {any}
     * @memberof UserFilterScheme
     */
    'phone'?: any | null;
    /**
     * Filter by email address
     * @type {any}
     * @memberof UserFilterScheme
     */
    'email'?: any | null;
    /**
     * Filter by email verification status
     * @type {any}
     * @memberof UserFilterScheme
     */
    'email_verified'?: any | null;
    /**
     * Filter by phone verification status
     * @type {any}
     * @memberof UserFilterScheme
     */
    'phone_verified'?: any | null;
    /**
     * Filter by user\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof UserFilterScheme
     */
    'role_id'?: any | null;
    /**
     * Filter by user\'s created status
     * @type {any}
     * @memberof UserFilterScheme
     */
    'created_at'?: any | null;
    /**
     * Filter by user\'s updated status
     * @type {any}
     * @memberof UserFilterScheme
     */
    'updated_at'?: any | null;
}
/**
 * 
 * @export
 * @interface UserListResponseInput
 */
export interface UserListResponseInput {
    /**
     * 
     * @type {any}
     * @memberof UserListResponseInput
     */
    'success'?: any;
    /**
     * 
     * @type {PageUserScheme}
     * @memberof UserListResponseInput
     */
    'result': PageUserScheme;
    /**
     * 
     * @type {UserFilterScheme}
     * @memberof UserListResponseInput
     */
    'filters'?: UserFilterScheme | null;
}
/**
 * 
 * @export
 * @interface UserListResponseOutput
 */
export interface UserListResponseOutput {
    /**
     * 
     * @type {any}
     * @memberof UserListResponseOutput
     */
    'success'?: any;
    /**
     * 
     * @type {PageUserScheme}
     * @memberof UserListResponseOutput
     */
    'result': PageUserScheme;
    /**
     * 
     * @type {UserFilterScheme}
     * @memberof UserListResponseOutput
     */
    'filters'?: UserFilterScheme | null;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {any}
     * @memberof UserResponse
     */
    'success'?: any;
    /**
     * 
     * @type {UserScheme}
     * @memberof UserResponse
     */
    'result': UserScheme;
}
/**
 * 
 * @export
 * @interface UserScheme
 */
export interface UserScheme {
    /**
     * User\'s phone number
     * @type {any}
     * @memberof UserScheme
     */
    'phone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof UserScheme
     */
    'email'?: any | null;
    /**
     * User\'s email verification status
     * @type {any}
     * @memberof UserScheme
     */
    'email_verified'?: any;
    /**
     * User\'s phone verification status
     * @type {any}
     * @memberof UserScheme
     */
    'phone_verified'?: any;
    /**
     * User\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof UserScheme
     */
    'role_id'?: any | null;
    /**
     * ID of integration service the user belongs to
     * @type {any}
     * @memberof UserScheme
     */
    'service_id': any;
    /**
     * User\'s deleted status: delete or not
     * @type {any}
     * @memberof UserScheme
     */
    'deleted_at'?: any | null;
    /**
     * User\'s created status: delete or not
     * @type {any}
     * @memberof UserScheme
     */
    'created_at'?: any | null;
    /**
     * User\'s updated status: delete or not
     * @type {any}
     * @memberof UserScheme
     */
    'updated_at'?: any | null;
    /**
     * User\'s ID
     * @type {any}
     * @memberof UserScheme
     */
    'id': any | null;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'loc': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'msg': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'type': any;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Closes all user\'s sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeSessionsAuthCloseSessionsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/close-sessions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates and returns user\'s auth code to use with phone number
         * @param {any} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneCodeAuthPhoneCodeGet: async (phoneNumber: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('getPhoneCodeAuthPhoneCodeGet', 'phoneNumber', phoneNumber)
            const localVarPath = `/auth/phone-code/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of user\'s sessions
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsAuthSessionListGet: async (page?: any, size?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/session-list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticates user
         * @param {any} serviceName Name of integration service current user belongs to
         * @param {any} body 
         * @param {any | null} [continueUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost: async (serviceName: any, body: any, continueUrl?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('loginAuthLoginPost', 'serviceName', serviceName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('loginAuthLoginPost', 'body', body)
            const localVarPath = `/auth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }

            if (continueUrl !== undefined) {
                localVarQueryParameter['continue_url'] = continueUrl;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks refresh token and returns new tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokensAuthRefreshPost: async (refreshToken: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshTokensAuthRefreshPost', 'refreshToken', refreshToken)
            const localVarPath = `/auth/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (refreshToken !== undefined) { 
                localVarFormParams.set('refresh_token', refreshToken as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates auth token and returns response with status code 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateTokenAuthValidateTokenPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/validate-token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Closes all user\'s sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeSessionsAuthCloseSessionsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeSessionsAuthCloseSessionsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates and returns user\'s auth code to use with phone number
         * @param {any} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhoneCodeAuthPhoneCodeGet(phoneNumber: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneCodeScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhoneCodeAuthPhoneCodeGet(phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of user\'s sessions
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionsAuthSessionListGet(page?: any, size?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionsListSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionsAuthSessionListGet(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authenticates user
         * @param {any} serviceName Name of integration service current user belongs to
         * @param {any} body 
         * @param {any | null} [continueUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAuthLoginPost(serviceName: any, body: any, continueUrl?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAuthLoginPost(serviceName, body, continueUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks refresh token and returns new tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTokensAuthRefreshPost(refreshToken: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWTScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTokensAuthRefreshPost(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validates auth token and returns response with status code 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateTokenAuthValidateTokenPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateTokenAuthValidateTokenPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Closes all user\'s sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeSessionsAuthCloseSessionsPost(options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.closeSessionsAuthCloseSessionsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates and returns user\'s auth code to use with phone number
         * @param {any} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneCodeAuthPhoneCodeGet(phoneNumber: any, options?: any): AxiosPromise<PhoneCodeScheme> {
            return localVarFp.getPhoneCodeAuthPhoneCodeGet(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of user\'s sessions
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsAuthSessionListGet(page?: any, size?: any, options?: any): AxiosPromise<SessionsListSchema> {
            return localVarFp.getSessionsAuthSessionListGet(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticates user
         * @param {any} serviceName Name of integration service current user belongs to
         * @param {any} body 
         * @param {any | null} [continueUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost(serviceName: any, body: any, continueUrl?: any | null, options?: any): AxiosPromise<any> {
            return localVarFp.loginAuthLoginPost(serviceName, body, continueUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks refresh token and returns new tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokensAuthRefreshPost(refreshToken: any, options?: any): AxiosPromise<JWTScheme> {
            return localVarFp.refreshTokensAuthRefreshPost(refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates auth token and returns response with status code 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateTokenAuthValidateTokenPost(options?: any): AxiosPromise<any> {
            return localVarFp.validateTokenAuthValidateTokenPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Closes all user\'s sessions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public closeSessionsAuthCloseSessionsPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).closeSessionsAuthCloseSessionsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates and returns user\'s auth code to use with phone number
     * @param {any} phoneNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getPhoneCodeAuthPhoneCodeGet(phoneNumber: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getPhoneCodeAuthPhoneCodeGet(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of user\'s sessions
     * @param {any} [page] 
     * @param {any} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getSessionsAuthSessionListGet(page?: any, size?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getSessionsAuthSessionListGet(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticates user
     * @param {any} serviceName Name of integration service current user belongs to
     * @param {any} body 
     * @param {any | null} [continueUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAuthLoginPost(serviceName: any, body: any, continueUrl?: any | null, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAuthLoginPost(serviceName, body, continueUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks refresh token and returns new tokens
     * @param {any} refreshToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshTokensAuthRefreshPost(refreshToken: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshTokensAuthRefreshPost(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates auth token and returns response with status code 200
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public validateTokenAuthValidateTokenPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).validateTokenAuthValidateTokenPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientUsersApi - axios parameter creator
 * @export
 */
export const ClientUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Safely deletes the user by their ID from jwt payloads  Args:     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted
         * @summary Deletes the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsersDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the user by their id from jwt payload
         * @summary Get the user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user information by user\'s ID from jwt payload  Args:     updated_user (ClientEditUserSchema): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     ClientInfoResponse: The updated user\'s information.
         * @summary Change the user info
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsersPut: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserUsersPut', 'body', body)
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientUsersApi - functional programming interface
 * @export
 */
export const ClientUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Safely deletes the user by their ID from jwt payloads  Args:     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted
         * @summary Deletes the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserUsersDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserUsersDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the user by their id from jwt payload
         * @summary Get the user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user information by user\'s ID from jwt payload  Args:     updated_user (ClientEditUserSchema): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     ClientInfoResponse: The updated user\'s information.
         * @summary Change the user info
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserUsersPut(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserUsersPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientUsersApi - factory interface
 * @export
 */
export const ClientUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientUsersApiFp(configuration)
    return {
        /**
         * Safely deletes the user by their ID from jwt payloads  Args:     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted
         * @summary Deletes the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsersDelete(options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.deleteUserUsersDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the user by their id from jwt payload
         * @summary Get the user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsersGet(options?: any): AxiosPromise<ClientInfoResponse> {
            return localVarFp.getUserUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Update user information by user\'s ID from jwt payload  Args:     updated_user (ClientEditUserSchema): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     ClientInfoResponse: The updated user\'s information.
         * @summary Change the user info
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsersPut(body: any, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserUsersPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientUsersApi - object-oriented interface
 * @export
 * @class ClientUsersApi
 * @extends {BaseAPI}
 */
export class ClientUsersApi extends BaseAPI {
    /**
     * Safely deletes the user by their ID from jwt payloads  Args:     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted
     * @summary Deletes the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientUsersApi
     */
    public deleteUserUsersDelete(options?: AxiosRequestConfig) {
        return ClientUsersApiFp(this.configuration).deleteUserUsersDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the user by their id from jwt payload
     * @summary Get the user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientUsersApi
     */
    public getUserUsersGet(options?: AxiosRequestConfig) {
        return ClientUsersApiFp(this.configuration).getUserUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user information by user\'s ID from jwt payload  Args:     updated_user (ClientEditUserSchema): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     ClientInfoResponse: The updated user\'s information.
     * @summary Change the user info
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientUsersApi
     */
    public updateUserUsersPut(body: any, options?: AxiosRequestConfig) {
        return ClientUsersApiFp(this.configuration).updateUserUsersPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FrontApi - axios parameter creator
 * @export
 */
export const FrontApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Renders add credentials page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOauthCredsPageFrontOauthCredentialsAddGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/oauth-credentials/add/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the create service page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServicePageFrontCreateServiceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/create-service/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the confirm email page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmationPageFrontConfirmEmailGet: async (serviceName: any, continueUrl?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('emailConfirmationPageFrontConfirmEmailGet', 'serviceName', serviceName)
            const localVarPath = `/front/confirm-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }

            if (continueUrl !== undefined) {
                localVarQueryParameter['continue_url'] = continueUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageFrontGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginFrontLoginPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the login page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPageFrontLoginGet: async (serviceName: any, continueUrl?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('loginPageFrontLoginGet', 'serviceName', serviceName)
            const localVarPath = `/front/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }

            if (continueUrl !== undefined) {
                localVarQueryParameter['continue_url'] = continueUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the oauth creds page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthCredsPageFrontOauthCredsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/oauth-creds/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders password recovery page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryPageFrontPasswordRecoveryGet: async (serviceName: any, continueUrl?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('passwordRecoveryPageFrontPasswordRecoveryGet', 'serviceName', serviceName)
            const localVarPath = `/front/password-recovery/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }

            if (continueUrl !== undefined) {
                localVarQueryParameter['continue_url'] = continueUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the password reset page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetPageFrontPasswordResetGet: async (serviceName: any, continueUrl?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('passwordResetPageFrontPasswordResetGet', 'serviceName', serviceName)
            const localVarPath = `/front/password-reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }

            if (continueUrl !== undefined) {
                localVarQueryParameter['continue_url'] = continueUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the register page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPageFrontRegisterGet: async (serviceName: any, continueUrl?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('registerPageFrontRegisterGet', 'serviceName', serviceName)
            const localVarPath = `/front/register/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }

            if (continueUrl !== undefined) {
                localVarQueryParameter['continue_url'] = continueUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders the service login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceLoginPageFrontServiceLoginGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/service-login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders add settings page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSettingsPageFrontServiceSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/service/settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Renders update credentials page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOauthCredsPageFrontOauthCredentialsUpdateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/front/oauth-credentials/update/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontApi - functional programming interface
 * @export
 */
export const FrontApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Renders add credentials page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOauthCredsPageFrontOauthCredentialsAddGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOauthCredsPageFrontOauthCredentialsAddGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the create service page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServicePageFrontCreateServiceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServicePageFrontCreateServiceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the confirm email page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailConfirmationPageFrontConfirmEmailGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailConfirmationPageFrontConfirmEmailGet(serviceName, continueUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomePageFrontGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomePageFrontGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginFrontLoginPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginFrontLoginPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the login page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginPageFrontLoginGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginPageFrontLoginGet(serviceName, continueUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the oauth creds page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthCredsPageFrontOauthCredsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthCredsPageFrontOauthCredsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders password recovery page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordRecoveryPageFrontPasswordRecoveryGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordRecoveryPageFrontPasswordRecoveryGet(serviceName, continueUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the password reset page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetPageFrontPasswordResetGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetPageFrontPasswordResetGet(serviceName, continueUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the register page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPageFrontRegisterGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPageFrontRegisterGet(serviceName, continueUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders the service login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceLoginPageFrontServiceLoginGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceLoginPageFrontServiceLoginGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders add settings page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSettingsPageFrontServiceSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSettingsPageFrontServiceSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Renders update credentials page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOauthCredsPageFrontOauthCredentialsUpdateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOauthCredsPageFrontOauthCredentialsUpdateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontApi - factory interface
 * @export
 */
export const FrontApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontApiFp(configuration)
    return {
        /**
         * 
         * @summary Renders add credentials page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOauthCredsPageFrontOauthCredentialsAddGet(options?: any): AxiosPromise<any> {
            return localVarFp.addOauthCredsPageFrontOauthCredentialsAddGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the create service page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServicePageFrontCreateServiceGet(options?: any): AxiosPromise<any> {
            return localVarFp.createServicePageFrontCreateServiceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the confirm email page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailConfirmationPageFrontConfirmEmailGet(serviceName: any, continueUrl?: any | null, options?: any): AxiosPromise<any> {
            return localVarFp.emailConfirmationPageFrontConfirmEmailGet(serviceName, continueUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageFrontGet(options?: any): AxiosPromise<any> {
            return localVarFp.getHomePageFrontGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handle login and set the integration cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginFrontLoginPost(options?: any): AxiosPromise<any> {
            return localVarFp.loginFrontLoginPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the login page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPageFrontLoginGet(serviceName: any, continueUrl?: any | null, options?: any): AxiosPromise<any> {
            return localVarFp.loginPageFrontLoginGet(serviceName, continueUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the oauth creds page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthCredsPageFrontOauthCredsGet(options?: any): AxiosPromise<any> {
            return localVarFp.oauthCredsPageFrontOauthCredsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders password recovery page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryPageFrontPasswordRecoveryGet(serviceName: any, continueUrl?: any | null, options?: any): AxiosPromise<any> {
            return localVarFp.passwordRecoveryPageFrontPasswordRecoveryGet(serviceName, continueUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the password reset page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetPageFrontPasswordResetGet(serviceName: any, continueUrl?: any | null, options?: any): AxiosPromise<any> {
            return localVarFp.passwordResetPageFrontPasswordResetGet(serviceName, continueUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the register page
         * @param {any} serviceName Name of the service
         * @param {any | null} [continueUrl] URL to redirect after login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPageFrontRegisterGet(serviceName: any, continueUrl?: any | null, options?: any): AxiosPromise<any> {
            return localVarFp.registerPageFrontRegisterGet(serviceName, continueUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders the service login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceLoginPageFrontServiceLoginGet(options?: any): AxiosPromise<any> {
            return localVarFp.serviceLoginPageFrontServiceLoginGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders add settings page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSettingsPageFrontServiceSettingsGet(options?: any): AxiosPromise<any> {
            return localVarFp.serviceSettingsPageFrontServiceSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Renders update credentials page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOauthCredsPageFrontOauthCredentialsUpdateGet(options?: any): AxiosPromise<any> {
            return localVarFp.updateOauthCredsPageFrontOauthCredentialsUpdateGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontApi - object-oriented interface
 * @export
 * @class FrontApi
 * @extends {BaseAPI}
 */
export class FrontApi extends BaseAPI {
    /**
     * 
     * @summary Renders add credentials page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public addOauthCredsPageFrontOauthCredentialsAddGet(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).addOauthCredsPageFrontOauthCredentialsAddGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the create service page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public createServicePageFrontCreateServiceGet(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).createServicePageFrontCreateServiceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the confirm email page
     * @param {any} serviceName Name of the service
     * @param {any | null} [continueUrl] URL to redirect after login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public emailConfirmationPageFrontConfirmEmailGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).emailConfirmationPageFrontConfirmEmailGet(serviceName, continueUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the home page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public getHomePageFrontGet(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).getHomePageFrontGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handle login and set the integration cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public loginFrontLoginPost(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).loginFrontLoginPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the login page
     * @param {any} serviceName Name of the service
     * @param {any | null} [continueUrl] URL to redirect after login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public loginPageFrontLoginGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).loginPageFrontLoginGet(serviceName, continueUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the oauth creds page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public oauthCredsPageFrontOauthCredsGet(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).oauthCredsPageFrontOauthCredsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders password recovery page
     * @param {any} serviceName Name of the service
     * @param {any | null} [continueUrl] URL to redirect after login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public passwordRecoveryPageFrontPasswordRecoveryGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).passwordRecoveryPageFrontPasswordRecoveryGet(serviceName, continueUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the password reset page
     * @param {any} serviceName Name of the service
     * @param {any | null} [continueUrl] URL to redirect after login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public passwordResetPageFrontPasswordResetGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).passwordResetPageFrontPasswordResetGet(serviceName, continueUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the register page
     * @param {any} serviceName Name of the service
     * @param {any | null} [continueUrl] URL to redirect after login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public registerPageFrontRegisterGet(serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).registerPageFrontRegisterGet(serviceName, continueUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders the service login page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public serviceLoginPageFrontServiceLoginGet(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).serviceLoginPageFrontServiceLoginGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders add settings page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public serviceSettingsPageFrontServiceSettingsGet(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).serviceSettingsPageFrontServiceSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Renders update credentials page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontApi
     */
    public updateOauthCredsPageFrontOauthCredentialsUpdateGet(options?: AxiosRequestConfig) {
        return FrontApiFp(this.configuration).updateOauthCredsPageFrontOauthCredentialsUpdateGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntegrationServiceUsersApi - axios parameter creator
 * @export
 */
export const IntegrationServiceUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user by its ID
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIntegrationUsersUserIdGet: async (userId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserIntegrationUsersUserIdGet', 'userId', userId)
            const localVarPath = `/integration/users/{user_id}/`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationServiceUsersApi - functional programming interface
 * @export
 */
export const IntegrationServiceUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationServiceUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user by its ID
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserIntegrationUsersUserIdGet(userId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserIntegrationUsersUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationServiceUsersApi - factory interface
 * @export
 */
export const IntegrationServiceUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationServiceUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user by its ID
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIntegrationUsersUserIdGet(userId: any, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUserIntegrationUsersUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationServiceUsersApi - object-oriented interface
 * @export
 * @class IntegrationServiceUsersApi
 * @extends {BaseAPI}
 */
export class IntegrationServiceUsersApi extends BaseAPI {
    /**
     * 
     * @summary Get user by its ID
     * @param {any} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationServiceUsersApi
     */
    public getUserIntegrationUsersUserIdGet(userId: any, options?: AxiosRequestConfig) {
        return IntegrationServiceUsersApiFp(this.configuration).getUserIntegrationUsersUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OAuthApi - axios parameter creator
 * @export
 */
export const OAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Validates auth code from provider and returns user\'s tokens
         * @param {OAuthProvider} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackOauthProviderCallbackGet: async (provider: OAuthProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('callbackOauthProviderCallbackGet', 'provider', provider)
            const localVarPath = `/oauth/{provider}/callback`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of OAuth providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthProvidersOauthProvidersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/providers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns uri for user to redirect to OAuth provider
         * @param {OAuthProvider} provider 
         * @param {any} serviceName Name of integration service current user belongs to
         * @param {any | null} [continueUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToProviderOauthLoginGet: async (provider: OAuthProvider, serviceName: any, continueUrl?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('redirectToProviderOauthLoginGet', 'provider', provider)
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('redirectToProviderOauthLoginGet', 'serviceName', serviceName)
            const localVarPath = `/oauth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }

            if (continueUrl !== undefined) {
                localVarQueryParameter['continue_url'] = continueUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthApi - functional programming interface
 * @export
 */
export const OAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Validates auth code from provider and returns user\'s tokens
         * @param {OAuthProvider} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callbackOauthProviderCallbackGet(provider: OAuthProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callbackOauthProviderCallbackGet(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of OAuth providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOauthProvidersOauthProvidersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOauthProvidersOauthProvidersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns uri for user to redirect to OAuth provider
         * @param {OAuthProvider} provider 
         * @param {any} serviceName Name of integration service current user belongs to
         * @param {any | null} [continueUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectToProviderOauthLoginGet(provider: OAuthProvider, serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectToProviderOauthLoginGet(provider, serviceName, continueUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthApi - factory interface
 * @export
 */
export const OAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Validates auth code from provider and returns user\'s tokens
         * @param {OAuthProvider} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackOauthProviderCallbackGet(provider: OAuthProvider, options?: any): AxiosPromise<any> {
            return localVarFp.callbackOauthProviderCallbackGet(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of OAuth providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthProvidersOauthProvidersGet(options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.getOauthProvidersOauthProvidersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns uri for user to redirect to OAuth provider
         * @param {OAuthProvider} provider 
         * @param {any} serviceName Name of integration service current user belongs to
         * @param {any | null} [continueUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToProviderOauthLoginGet(provider: OAuthProvider, serviceName: any, continueUrl?: any | null, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.redirectToProviderOauthLoginGet(provider, serviceName, continueUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuthApi - object-oriented interface
 * @export
 * @class OAuthApi
 * @extends {BaseAPI}
 */
export class OAuthApi extends BaseAPI {
    /**
     * 
     * @summary Validates auth code from provider and returns user\'s tokens
     * @param {OAuthProvider} provider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public callbackOauthProviderCallbackGet(provider: OAuthProvider, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).callbackOauthProviderCallbackGet(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of OAuth providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public getOauthProvidersOauthProvidersGet(options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).getOauthProvidersOauthProvidersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns uri for user to redirect to OAuth provider
     * @param {OAuthProvider} provider 
     * @param {any} serviceName Name of integration service current user belongs to
     * @param {any | null} [continueUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public redirectToProviderOauthLoginGet(provider: OAuthProvider, serviceName: any, continueUrl?: any | null, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).redirectToProviderOauthLoginGet(provider, serviceName, continueUrl, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OAuthCredentialsApi - axios parameter creator
 * @export
 */
export const OAuthCredentialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Credentials
         * @param {OAuthCredentialsScheme} oAuthCredentialsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCredentialsIntegrationOauthCredentialsPost: async (oAuthCredentialsScheme: OAuthCredentialsScheme, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oAuthCredentialsScheme' is not null or undefined
            assertParamExists('createCredentialsIntegrationOauthCredentialsPost', 'oAuthCredentialsScheme', oAuthCredentialsScheme)
            const localVarPath = `/integration/oauth-credentials/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCredentialsScheme, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Credentials
         * @param {OAuthProvider} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCredentialsIntegrationOauthCredentialsDelete: async (provider: OAuthProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('deleteCredentialsIntegrationOauthCredentialsDelete', 'provider', provider)
            const localVarPath = `/integration/oauth-credentials/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Credential
         * @param {OAuthProvider} providerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentialIntegrationOauthCredentialsProviderNameGet: async (providerName: OAuthProvider, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerName' is not null or undefined
            assertParamExists('getCredentialIntegrationOauthCredentialsProviderNameGet', 'providerName', providerName)
            const localVarPath = `/integration/oauth-credentials/{provider_name}/`
                .replace(`{${"provider_name"}}`, encodeURIComponent(String(providerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentialsIntegrationOauthCredentialsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/oauth-credentials/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Credentials
         * @param {OAuthCredentialsScheme} oAuthCredentialsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCredentialsIntegrationOauthCredentialsPut: async (oAuthCredentialsScheme: OAuthCredentialsScheme, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oAuthCredentialsScheme' is not null or undefined
            assertParamExists('updateCredentialsIntegrationOauthCredentialsPut', 'oAuthCredentialsScheme', oAuthCredentialsScheme)
            const localVarPath = `/integration/oauth-credentials/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthCredentialsScheme, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthCredentialsApi - functional programming interface
 * @export
 */
export const OAuthCredentialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthCredentialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Credentials
         * @param {OAuthCredentialsScheme} oAuthCredentialsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCredentialsIntegrationOauthCredentialsPost(oAuthCredentialsScheme: OAuthCredentialsScheme, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCredentialsIntegrationOauthCredentialsPost(oAuthCredentialsScheme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Credentials
         * @param {OAuthProvider} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCredentialsIntegrationOauthCredentialsDelete(provider: OAuthProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCredentialsIntegrationOauthCredentialsDelete(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Credential
         * @param {OAuthProvider} providerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCredentialIntegrationOauthCredentialsProviderNameGet(providerName: OAuthProvider, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCredentialIntegrationOauthCredentialsProviderNameGet(providerName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCredentialsIntegrationOauthCredentialsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCredentialsIntegrationOauthCredentialsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Credentials
         * @param {OAuthCredentialsScheme} oAuthCredentialsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCredentialsIntegrationOauthCredentialsPut(oAuthCredentialsScheme: OAuthCredentialsScheme, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCredentialsIntegrationOauthCredentialsPut(oAuthCredentialsScheme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthCredentialsApi - factory interface
 * @export
 */
export const OAuthCredentialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthCredentialsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Credentials
         * @param {OAuthCredentialsScheme} oAuthCredentialsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCredentialsIntegrationOauthCredentialsPost(oAuthCredentialsScheme: OAuthCredentialsScheme, options?: any): AxiosPromise<any> {
            return localVarFp.createCredentialsIntegrationOauthCredentialsPost(oAuthCredentialsScheme, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Credentials
         * @param {OAuthProvider} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCredentialsIntegrationOauthCredentialsDelete(provider: OAuthProvider, options?: any): AxiosPromise<any> {
            return localVarFp.deleteCredentialsIntegrationOauthCredentialsDelete(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Credential
         * @param {OAuthProvider} providerName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentialIntegrationOauthCredentialsProviderNameGet(providerName: OAuthProvider, options?: any): AxiosPromise<any> {
            return localVarFp.getCredentialIntegrationOauthCredentialsProviderNameGet(providerName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredentialsIntegrationOauthCredentialsGet(options?: any): AxiosPromise<any> {
            return localVarFp.getCredentialsIntegrationOauthCredentialsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Credentials
         * @param {OAuthCredentialsScheme} oAuthCredentialsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCredentialsIntegrationOauthCredentialsPut(oAuthCredentialsScheme: OAuthCredentialsScheme, options?: any): AxiosPromise<any> {
            return localVarFp.updateCredentialsIntegrationOauthCredentialsPut(oAuthCredentialsScheme, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuthCredentialsApi - object-oriented interface
 * @export
 * @class OAuthCredentialsApi
 * @extends {BaseAPI}
 */
export class OAuthCredentialsApi extends BaseAPI {
    /**
     * 
     * @summary Create Credentials
     * @param {OAuthCredentialsScheme} oAuthCredentialsScheme 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthCredentialsApi
     */
    public createCredentialsIntegrationOauthCredentialsPost(oAuthCredentialsScheme: OAuthCredentialsScheme, options?: AxiosRequestConfig) {
        return OAuthCredentialsApiFp(this.configuration).createCredentialsIntegrationOauthCredentialsPost(oAuthCredentialsScheme, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Credentials
     * @param {OAuthProvider} provider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthCredentialsApi
     */
    public deleteCredentialsIntegrationOauthCredentialsDelete(provider: OAuthProvider, options?: AxiosRequestConfig) {
        return OAuthCredentialsApiFp(this.configuration).deleteCredentialsIntegrationOauthCredentialsDelete(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Credential
     * @param {OAuthProvider} providerName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthCredentialsApi
     */
    public getCredentialIntegrationOauthCredentialsProviderNameGet(providerName: OAuthProvider, options?: AxiosRequestConfig) {
        return OAuthCredentialsApiFp(this.configuration).getCredentialIntegrationOauthCredentialsProviderNameGet(providerName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthCredentialsApi
     */
    public getCredentialsIntegrationOauthCredentialsGet(options?: AxiosRequestConfig) {
        return OAuthCredentialsApiFp(this.configuration).getCredentialsIntegrationOauthCredentialsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Credentials
     * @param {OAuthCredentialsScheme} oAuthCredentialsScheme 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthCredentialsApi
     */
    public updateCredentialsIntegrationOauthCredentialsPut(oAuthCredentialsScheme: OAuthCredentialsScheme, options?: AxiosRequestConfig) {
        return OAuthCredentialsApiFp(this.configuration).updateCredentialsIntegrationOauthCredentialsPut(oAuthCredentialsScheme, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PasswordRecoveryApi - axios parameter creator
 * @export
 */
export const PasswordRecoveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Password update via confirmation code. Args:     confirmation_code: str, code from message.     request: PasswordSchema, two passwords.     db_session: AsyncSession, database session.
         * @summary Validates confirmation code and updates password
         * @param {any} confirmationCode 
         * @param {PasswordSchema} passwordSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryPasswordRecoveryConfirmationCodePatch: async (confirmationCode: any, passwordSchema: PasswordSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationCode' is not null or undefined
            assertParamExists('passwordRecoveryPasswordRecoveryConfirmationCodePatch', 'confirmationCode', confirmationCode)
            // verify required parameter 'passwordSchema' is not null or undefined
            assertParamExists('passwordRecoveryPasswordRecoveryConfirmationCodePatch', 'passwordSchema', passwordSchema)
            const localVarPath = `/password-recovery/{confirmation_code}`
                .replace(`{${"confirmation_code"}}`, encodeURIComponent(String(confirmationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Password recovery request via email or phone. Args:     credentials: EmailRecoverySchema | PhoneRecoverySchema, user data.     db_session: AsyncSession, database session.
         * @summary Generates password recovery code and sends it
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryRequestPasswordRecoveryRequestPost: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('passwordRecoveryRequestPasswordRecoveryRequestPost', 'body', body)
            const localVarPath = `/password-recovery/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordRecoveryApi - functional programming interface
 * @export
 */
export const PasswordRecoveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordRecoveryApiAxiosParamCreator(configuration)
    return {
        /**
         * Password update via confirmation code. Args:     confirmation_code: str, code from message.     request: PasswordSchema, two passwords.     db_session: AsyncSession, database session.
         * @summary Validates confirmation code and updates password
         * @param {any} confirmationCode 
         * @param {PasswordSchema} passwordSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode: any, passwordSchema: PasswordSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode, passwordSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Password recovery request via email or phone. Args:     credentials: EmailRecoverySchema | PhoneRecoverySchema, user data.     db_session: AsyncSession, database session.
         * @summary Generates password recovery code and sends it
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordRecoveryRequestPasswordRecoveryRequestPost(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmationCodeResponseScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordRecoveryRequestPasswordRecoveryRequestPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordRecoveryApi - factory interface
 * @export
 */
export const PasswordRecoveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordRecoveryApiFp(configuration)
    return {
        /**
         * Password update via confirmation code. Args:     confirmation_code: str, code from message.     request: PasswordSchema, two passwords.     db_session: AsyncSession, database session.
         * @summary Validates confirmation code and updates password
         * @param {any} confirmationCode 
         * @param {PasswordSchema} passwordSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode: any, passwordSchema: PasswordSchema, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode, passwordSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Password recovery request via email or phone. Args:     credentials: EmailRecoverySchema | PhoneRecoverySchema, user data.     db_session: AsyncSession, database session.
         * @summary Generates password recovery code and sends it
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryRequestPasswordRecoveryRequestPost(body: any, options?: any): AxiosPromise<ConfirmationCodeResponseScheme> {
            return localVarFp.passwordRecoveryRequestPasswordRecoveryRequestPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordRecoveryApi - object-oriented interface
 * @export
 * @class PasswordRecoveryApi
 * @extends {BaseAPI}
 */
export class PasswordRecoveryApi extends BaseAPI {
    /**
     * Password update via confirmation code. Args:     confirmation_code: str, code from message.     request: PasswordSchema, two passwords.     db_session: AsyncSession, database session.
     * @summary Validates confirmation code and updates password
     * @param {any} confirmationCode 
     * @param {PasswordSchema} passwordSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordRecoveryApi
     */
    public passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode: any, passwordSchema: PasswordSchema, options?: AxiosRequestConfig) {
        return PasswordRecoveryApiFp(this.configuration).passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode, passwordSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Password recovery request via email or phone. Args:     credentials: EmailRecoverySchema | PhoneRecoverySchema, user data.     db_session: AsyncSession, database session.
     * @summary Generates password recovery code and sends it
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordRecoveryApi
     */
    public passwordRecoveryRequestPasswordRecoveryRequestPost(body: any, options?: AxiosRequestConfig) {
        return PasswordRecoveryApiFp(this.configuration).passwordRecoveryRequestPasswordRecoveryRequestPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegistrationApi - axios parameter creator
 * @export
 */
export const RegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Determine if the confirmation is for email or phone and call the appropriate method.  Args:     confirmation_code: str, confirmation code from email or phone.     db_session: AsyncSession, database session.
         * @summary Confirmation
         * @param {any} confirmationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationRegistrationConfirmationCodePatch: async (confirmationCode: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationCode' is not null or undefined
            assertParamExists('confirmationRegistrationConfirmationCodePatch', 'confirmationCode', confirmationCode)
            const localVarPath = `/registration/{confirmation_code}`
                .replace(`{${"confirmation_code"}}`, encodeURIComponent(String(confirmationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a new user using either email or phone, depending on the provided data.  Args:     request (EmailRegisterScheme | PhoneRegisterScheme): The registration         data provided by the user. This can either be an email-based         registration scheme or a phone-based registration scheme.     db_session (AsyncSession): The database session used to interact with         the database.     service (str, optional): The name of the application from which the         registration is initiated.  Returns:     Response200Scheme: A response object containing a success message.  Raises:     HTTPException: If the user is already registered, a 400 error is         returned with the relevant exception scheme.
         * @summary Registration
         * @param {any} serviceName Name of the application
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationRegistrationPost: async (serviceName: any, body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('registrationRegistrationPost', 'serviceName', serviceName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('registrationRegistrationPost', 'body', body)
            const localVarPath = `/registration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serviceName !== undefined) {
                localVarQueryParameter['service_name'] = serviceName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend a confirmation code to the user via email or phone.  This endpoint handles the resending of confirmation codes based on the user\'s email or phone number. If a valid user is found, it marks the old confirmation code as used and generates a new one. The new code is then sent to the user.  Args:     request (EmailResendCodeScheme | PhoneResendCodeScheme): A request     object containing either email or phone details.     db_session (AsyncSession): The database session dependency.  Raises:     HTTPException: If the user does not exist or if there is an issue     sending the email.  Returns:     Response200Scheme: A response indicating the success of the operation.
         * @summary Resend Code
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendCodeRegistrationResendCodePost: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('resendCodeRegistrationResendCodePost', 'body', body)
            const localVarPath = `/registration/resend_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationApi - functional programming interface
 * @export
 */
export const RegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Determine if the confirmation is for email or phone and call the appropriate method.  Args:     confirmation_code: str, confirmation code from email or phone.     db_session: AsyncSession, database session.
         * @summary Confirmation
         * @param {any} confirmationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmationRegistrationConfirmationCodePatch(confirmationCode: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmationRegistrationConfirmationCodePatch(confirmationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a new user using either email or phone, depending on the provided data.  Args:     request (EmailRegisterScheme | PhoneRegisterScheme): The registration         data provided by the user. This can either be an email-based         registration scheme or a phone-based registration scheme.     db_session (AsyncSession): The database session used to interact with         the database.     service (str, optional): The name of the application from which the         registration is initiated.  Returns:     Response200Scheme: A response object containing a success message.  Raises:     HTTPException: If the user is already registered, a 400 error is         returned with the relevant exception scheme.
         * @summary Registration
         * @param {any} serviceName Name of the application
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrationRegistrationPost(serviceName: any, body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrationRegistrationPost(serviceName, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resend a confirmation code to the user via email or phone.  This endpoint handles the resending of confirmation codes based on the user\'s email or phone number. If a valid user is found, it marks the old confirmation code as used and generates a new one. The new code is then sent to the user.  Args:     request (EmailResendCodeScheme | PhoneResendCodeScheme): A request     object containing either email or phone details.     db_session (AsyncSession): The database session dependency.  Raises:     HTTPException: If the user does not exist or if there is an issue     sending the email.  Returns:     Response200Scheme: A response indicating the success of the operation.
         * @summary Resend Code
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendCodeRegistrationResendCodePost(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendCodeRegistrationResendCodePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationApi - factory interface
 * @export
 */
export const RegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationApiFp(configuration)
    return {
        /**
         * Determine if the confirmation is for email or phone and call the appropriate method.  Args:     confirmation_code: str, confirmation code from email or phone.     db_session: AsyncSession, database session.
         * @summary Confirmation
         * @param {any} confirmationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationRegistrationConfirmationCodePatch(confirmationCode: any, options?: any): AxiosPromise<any> {
            return localVarFp.confirmationRegistrationConfirmationCodePatch(confirmationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a new user using either email or phone, depending on the provided data.  Args:     request (EmailRegisterScheme | PhoneRegisterScheme): The registration         data provided by the user. This can either be an email-based         registration scheme or a phone-based registration scheme.     db_session (AsyncSession): The database session used to interact with         the database.     service (str, optional): The name of the application from which the         registration is initiated.  Returns:     Response200Scheme: A response object containing a success message.  Raises:     HTTPException: If the user is already registered, a 400 error is         returned with the relevant exception scheme.
         * @summary Registration
         * @param {any} serviceName Name of the application
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationRegistrationPost(serviceName: any, body: any, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.registrationRegistrationPost(serviceName, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend a confirmation code to the user via email or phone.  This endpoint handles the resending of confirmation codes based on the user\'s email or phone number. If a valid user is found, it marks the old confirmation code as used and generates a new one. The new code is then sent to the user.  Args:     request (EmailResendCodeScheme | PhoneResendCodeScheme): A request     object containing either email or phone details.     db_session (AsyncSession): The database session dependency.  Raises:     HTTPException: If the user does not exist or if there is an issue     sending the email.  Returns:     Response200Scheme: A response indicating the success of the operation.
         * @summary Resend Code
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendCodeRegistrationResendCodePost(body: any, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.resendCodeRegistrationResendCodePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationApi - object-oriented interface
 * @export
 * @class RegistrationApi
 * @extends {BaseAPI}
 */
export class RegistrationApi extends BaseAPI {
    /**
     * Determine if the confirmation is for email or phone and call the appropriate method.  Args:     confirmation_code: str, confirmation code from email or phone.     db_session: AsyncSession, database session.
     * @summary Confirmation
     * @param {any} confirmationCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public confirmationRegistrationConfirmationCodePatch(confirmationCode: any, options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).confirmationRegistrationConfirmationCodePatch(confirmationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a new user using either email or phone, depending on the provided data.  Args:     request (EmailRegisterScheme | PhoneRegisterScheme): The registration         data provided by the user. This can either be an email-based         registration scheme or a phone-based registration scheme.     db_session (AsyncSession): The database session used to interact with         the database.     service (str, optional): The name of the application from which the         registration is initiated.  Returns:     Response200Scheme: A response object containing a success message.  Raises:     HTTPException: If the user is already registered, a 400 error is         returned with the relevant exception scheme.
     * @summary Registration
     * @param {any} serviceName Name of the application
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public registrationRegistrationPost(serviceName: any, body: any, options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).registrationRegistrationPost(serviceName, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resend a confirmation code to the user via email or phone.  This endpoint handles the resending of confirmation codes based on the user\'s email or phone number. If a valid user is found, it marks the old confirmation code as used and generates a new one. The new code is then sent to the user.  Args:     request (EmailResendCodeScheme | PhoneResendCodeScheme): A request     object containing either email or phone details.     db_session (AsyncSession): The database session dependency.  Raises:     HTTPException: If the user does not exist or if there is an issue     sending the email.  Returns:     Response200Scheme: A response indicating the success of the operation.
     * @summary Resend Code
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public resendCodeRegistrationResendCodePost(body: any, options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).resendCodeRegistrationResendCodePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServiceIntegrationApi - axios parameter creator
 * @export
 */
export const ServiceIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates integration api key
         * @param {any} [expiresAt] Integration service API key expiration time (seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKeyIntegrationServicesKeysPost: async (expiresAt?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/services/keys/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)

            if (expiresAt !== undefined) {
                localVarQueryParameter['expires_at'] = expiresAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates integration service by name
         * @param {CreateIntegrationServiceSchema} createIntegrationServiceSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegrationServiceIntegrationServicesPost: async (createIntegrationServiceSchema: CreateIntegrationServiceSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIntegrationServiceSchema' is not null or undefined
            assertParamExists('createIntegrationServiceIntegrationServicesPost', 'createIntegrationServiceSchema', createIntegrationServiceSchema)
            const localVarPath = `/integration/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIntegrationServiceSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivates integration api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateApiKeyIntegrationKeysDeactivatePatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/keys/deactivate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete integration api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKeyIntegrationKeysDeletePatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/keys/delete/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes integration service by api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationServiceIntegrationServicesDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeysIntegrationServicesKeysGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/services/keys/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieve integration auth providers
         * @param {any} serviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthProvidersIntegrationServiceNameAuthProvidersGet: async (serviceName: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('getAuthProvidersIntegrationServiceNameAuthProvidersGet', 'serviceName', serviceName)
            const localVarPath = `/integration/{service_name}/auth-providers/`
                .replace(`{${"service_name"}}`, encodeURIComponent(String(serviceName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns info about integration service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationServiceInfoIntegrationServicesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login by API key
         * @param {any | null} [redirectUrl] The URL to redirect to after successful login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationLoginByApiKeyIntegrationLoginGet: async (redirectUrl?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)

            if (redirectUrl !== undefined) {
                localVarQueryParameter['redirect_url'] = redirectUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceIntegrationApi - functional programming interface
 * @export
 */
export const ServiceIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates integration api key
         * @param {any} [expiresAt] Integration service API key expiration time (seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApiKeyIntegrationServicesKeysPost(expiresAt?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationServiceApiKeyResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApiKeyIntegrationServicesKeysPost(expiresAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates integration service by name
         * @param {CreateIntegrationServiceSchema} createIntegrationServiceSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIntegrationServiceIntegrationServicesPost(createIntegrationServiceSchema: CreateIntegrationServiceSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationServiceResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIntegrationServiceIntegrationServicesPost(createIntegrationServiceSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivates integration api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateApiKeyIntegrationKeysDeactivatePatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateApiKeyIntegrationKeysDeactivatePatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete integration api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiKeyIntegrationKeysDeletePatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiKeyIntegrationKeysDeletePatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes integration service by api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIntegrationServiceIntegrationServicesDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIntegrationServiceIntegrationServicesDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiKeysIntegrationServicesKeysGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationServiceAPIKeyListResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiKeysIntegrationServicesKeysGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieve integration auth providers
         * @param {any} serviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthProvidersIntegrationServiceNameAuthProvidersGet(serviceName: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthProvidersIntegrationServiceNameAuthProvidersGet(serviceName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns info about integration service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrationServiceInfoIntegrationServicesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationServiceResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrationServiceInfoIntegrationServicesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login by API key
         * @param {any | null} [redirectUrl] The URL to redirect to after successful login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationLoginByApiKeyIntegrationLoginGet(redirectUrl?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationLoginByApiKeyIntegrationLoginGet(redirectUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceIntegrationApi - factory interface
 * @export
 */
export const ServiceIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates integration api key
         * @param {any} [expiresAt] Integration service API key expiration time (seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiKeyIntegrationServicesKeysPost(expiresAt?: any, options?: any): AxiosPromise<IntegrationServiceApiKeyResponseSchema> {
            return localVarFp.createApiKeyIntegrationServicesKeysPost(expiresAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates integration service by name
         * @param {CreateIntegrationServiceSchema} createIntegrationServiceSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegrationServiceIntegrationServicesPost(createIntegrationServiceSchema: CreateIntegrationServiceSchema, options?: any): AxiosPromise<IntegrationServiceResponseSchema> {
            return localVarFp.createIntegrationServiceIntegrationServicesPost(createIntegrationServiceSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivates integration api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateApiKeyIntegrationKeysDeactivatePatch(options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.deactivateApiKeyIntegrationKeysDeactivatePatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete integration api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiKeyIntegrationKeysDeletePatch(options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.deleteApiKeyIntegrationKeysDeletePatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes integration service by api key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationServiceIntegrationServicesDelete(options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.deleteIntegrationServiceIntegrationServicesDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiKeysIntegrationServicesKeysGet(options?: any): AxiosPromise<IntegrationServiceAPIKeyListResponseSchema> {
            return localVarFp.getApiKeysIntegrationServicesKeysGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieve integration auth providers
         * @param {any} serviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthProvidersIntegrationServiceNameAuthProvidersGet(serviceName: any, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.getAuthProvidersIntegrationServiceNameAuthProvidersGet(serviceName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns info about integration service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationServiceInfoIntegrationServicesGet(options?: any): AxiosPromise<IntegrationServiceResponseSchema> {
            return localVarFp.getIntegrationServiceInfoIntegrationServicesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login by API key
         * @param {any | null} [redirectUrl] The URL to redirect to after successful login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationLoginByApiKeyIntegrationLoginGet(redirectUrl?: any | null, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.integrationLoginByApiKeyIntegrationLoginGet(redirectUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceIntegrationApi - object-oriented interface
 * @export
 * @class ServiceIntegrationApi
 * @extends {BaseAPI}
 */
export class ServiceIntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Creates integration api key
     * @param {any} [expiresAt] Integration service API key expiration time (seconds)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public createApiKeyIntegrationServicesKeysPost(expiresAt?: any, options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).createApiKeyIntegrationServicesKeysPost(expiresAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates integration service by name
     * @param {CreateIntegrationServiceSchema} createIntegrationServiceSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public createIntegrationServiceIntegrationServicesPost(createIntegrationServiceSchema: CreateIntegrationServiceSchema, options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).createIntegrationServiceIntegrationServicesPost(createIntegrationServiceSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivates integration api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public deactivateApiKeyIntegrationKeysDeactivatePatch(options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).deactivateApiKeyIntegrationKeysDeactivatePatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete integration api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public deleteApiKeyIntegrationKeysDeletePatch(options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).deleteApiKeyIntegrationKeysDeletePatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes integration service by api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public deleteIntegrationServiceIntegrationServicesDelete(options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).deleteIntegrationServiceIntegrationServicesDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of api keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public getApiKeysIntegrationServicesKeysGet(options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).getApiKeysIntegrationServicesKeysGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieve integration auth providers
     * @param {any} serviceName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public getAuthProvidersIntegrationServiceNameAuthProvidersGet(serviceName: any, options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).getAuthProvidersIntegrationServiceNameAuthProvidersGet(serviceName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns info about integration service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public getIntegrationServiceInfoIntegrationServicesGet(options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).getIntegrationServiceInfoIntegrationServicesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login by API key
     * @param {any | null} [redirectUrl] The URL to redirect to after successful login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceIntegrationApi
     */
    public integrationLoginByApiKeyIntegrationLoginGet(redirectUrl?: any | null, options?: AxiosRequestConfig) {
        return ServiceIntegrationApiFp(this.configuration).integrationLoginByApiKeyIntegrationLoginGet(redirectUrl, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServiceSettingsApi - axios parameter creator
 * @export
 */
export const ServiceSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get service settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsIntegrationSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration/settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update service settings
         * @param {UpdateServiceSettingsScheme} updateServiceSettingsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsIntegrationSettingsPatch: async (updateServiceSettingsScheme: UpdateServiceSettingsScheme, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateServiceSettingsScheme' is not null or undefined
            assertParamExists('updateSettingsIntegrationSettingsPatch', 'updateServiceSettingsScheme', updateServiceSettingsScheme)
            const localVarPath = `/integration/settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Integration-Token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceSettingsScheme, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceSettingsApi - functional programming interface
 * @export
 */
export const ServiceSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get service settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsIntegrationSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseServiceSettingsOutScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsIntegrationSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update service settings
         * @param {UpdateServiceSettingsScheme} updateServiceSettingsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettingsIntegrationSettingsPatch(updateServiceSettingsScheme: UpdateServiceSettingsScheme, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseServiceSettingsOutScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettingsIntegrationSettingsPatch(updateServiceSettingsScheme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceSettingsApi - factory interface
 * @export
 */
export const ServiceSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get service settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsIntegrationSettingsGet(options?: any): AxiosPromise<DataResponseServiceSettingsOutScheme> {
            return localVarFp.getSettingsIntegrationSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update service settings
         * @param {UpdateServiceSettingsScheme} updateServiceSettingsScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsIntegrationSettingsPatch(updateServiceSettingsScheme: UpdateServiceSettingsScheme, options?: any): AxiosPromise<DataResponseServiceSettingsOutScheme> {
            return localVarFp.updateSettingsIntegrationSettingsPatch(updateServiceSettingsScheme, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceSettingsApi - object-oriented interface
 * @export
 * @class ServiceSettingsApi
 * @extends {BaseAPI}
 */
export class ServiceSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get service settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceSettingsApi
     */
    public getSettingsIntegrationSettingsGet(options?: AxiosRequestConfig) {
        return ServiceSettingsApiFp(this.configuration).getSettingsIntegrationSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update service settings
     * @param {UpdateServiceSettingsScheme} updateServiceSettingsScheme 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceSettingsApi
     */
    public updateSettingsIntegrationSettingsPatch(updateServiceSettingsScheme: UpdateServiceSettingsScheme, options?: AxiosRequestConfig) {
        return ServiceSettingsApiFp(this.configuration).updateSettingsIntegrationSettingsPatch(updateServiceSettingsScheme, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new user.  Args:     new_user (UserCreateSchema): data for the new user.     db_session (CustomSession).     jwt_payload (dict).  Returns:     UserResponse: Newly created user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Creates a new user
         * @param {CreateUserScheme} createUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAdminUsersPost: async (createUserScheme: CreateUserScheme, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserScheme' is not null or undefined
            assertParamExists('createUserAdminUsersPost', 'createUserScheme', createUserScheme)
            const localVarPath = `/admin/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserScheme, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a user from the system  Args:     user_id (UUID): The ID of the user to be deleted.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Delete a user
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAdminUsersUserIdDelete: async (userId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserAdminUsersUserIdDelete', 'userId', userId)
            const localVarPath = `/admin/users/{user_id}/`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a user by their ID.  Args:     user_id (UUID): The user ID.     db_session (CustomAsyncSession): The database session.     jwt_payload (dict): The JWT payload obtained by the `JWTBearerAdmin`     dependency.  Returns:     UserResponse: A response object containing the user\'s information.  Raises:     HTTPException: If the user with the specified ID is not found.
         * @summary Get the user info
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdminUsersUserIdGet: async (userId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserAdminUsersUserIdGet', 'userId', userId)
            const localVarPath = `/admin/users/{user_id}/`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of users filtered by various options. This endpoint allows you to retrieve a list of users based on various filter criteria. The following filters are available:  Args:     phone: Filter by phone number (string)     email: Filter by email address (string)     email_verified: Filter by email verification status (boolean, example:     true)     phone_verified: Filter by phone verification status (boolean, example:     true)     role_id: Filter by user role (integer)     created_at: Filter by creation date (datetime)     updated_at: Filter by update date (datetime)     db_session (CustomSession)     params (UserCreateSchema): Filter params the same as args     jwt_payload (dict[str]): bearer token payload  Returns:     UserListResponse: A response object containing the list of users and     the applied filters.
         * @summary Get users
         * @param {any | null} [phone] Filter by phone number
         * @param {any | null} [email] Filter by email address
         * @param {any | null} [emailVerified] Filter by email verification status
         * @param {any | null} [phoneVerified] Filter by phone verification status
         * @param {any | null} [roleId] Filter by user role
         * @param {any | null} [createdAt] Filter by creation date
         * @param {any | null} [updatedAt] Filter by update date
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersAdminUsersGet: async (phone?: any | null, email?: any | null, emailVerified?: any | null, phoneVerified?: any | null, roleId?: any | null, createdAt?: any | null, updatedAt?: any | null, page?: any, size?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (emailVerified !== undefined) {
                localVarQueryParameter['email_verified'] = emailVerified;
            }

            if (phoneVerified !== undefined) {
                localVarQueryParameter['phone_verified'] = phoneVerified;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['created_at'] = createdAt;
            }

            if (updatedAt !== undefined) {
                localVarQueryParameter['updated_at'] = updatedAt;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user information  Args:     user_id(UUID): user id for update     updated_user (EditUserScheme): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     UserResponse: The updated user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Change the user info
         * @param {any} userId 
         * @param {EditUserScheme} editUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAdminUsersUserIdPut: async (userId: any, editUserScheme: EditUserScheme, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserAdminUsersUserIdPut', 'userId', userId)
            // verify required parameter 'editUserScheme' is not null or undefined
            assertParamExists('updateUserAdminUsersUserIdPut', 'editUserScheme', editUserScheme)
            const localVarPath = `/admin/users/{user_id}/`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUserScheme, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new user.  Args:     new_user (UserCreateSchema): data for the new user.     db_session (CustomSession).     jwt_payload (dict).  Returns:     UserResponse: Newly created user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Creates a new user
         * @param {CreateUserScheme} createUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserAdminUsersPost(createUserScheme: CreateUserScheme, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserAdminUsersPost(createUserScheme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a user from the system  Args:     user_id (UUID): The ID of the user to be deleted.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Delete a user
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAdminUsersUserIdDelete(userId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAdminUsersUserIdDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a user by their ID.  Args:     user_id (UUID): The user ID.     db_session (CustomAsyncSession): The database session.     jwt_payload (dict): The JWT payload obtained by the `JWTBearerAdmin`     dependency.  Returns:     UserResponse: A response object containing the user\'s information.  Raises:     HTTPException: If the user with the specified ID is not found.
         * @summary Get the user info
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAdminUsersUserIdGet(userId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAdminUsersUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of users filtered by various options. This endpoint allows you to retrieve a list of users based on various filter criteria. The following filters are available:  Args:     phone: Filter by phone number (string)     email: Filter by email address (string)     email_verified: Filter by email verification status (boolean, example:     true)     phone_verified: Filter by phone verification status (boolean, example:     true)     role_id: Filter by user role (integer)     created_at: Filter by creation date (datetime)     updated_at: Filter by update date (datetime)     db_session (CustomSession)     params (UserCreateSchema): Filter params the same as args     jwt_payload (dict[str]): bearer token payload  Returns:     UserListResponse: A response object containing the list of users and     the applied filters.
         * @summary Get users
         * @param {any | null} [phone] Filter by phone number
         * @param {any | null} [email] Filter by email address
         * @param {any | null} [emailVerified] Filter by email verification status
         * @param {any | null} [phoneVerified] Filter by phone verification status
         * @param {any | null} [roleId] Filter by user role
         * @param {any | null} [createdAt] Filter by creation date
         * @param {any | null} [updatedAt] Filter by update date
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersAdminUsersGet(phone?: any | null, email?: any | null, emailVerified?: any | null, phoneVerified?: any | null, roleId?: any | null, createdAt?: any | null, updatedAt?: any | null, page?: any, size?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponseInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersAdminUsersGet(phone, email, emailVerified, phoneVerified, roleId, createdAt, updatedAt, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user information  Args:     user_id(UUID): user id for update     updated_user (EditUserScheme): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     UserResponse: The updated user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Change the user info
         * @param {any} userId 
         * @param {EditUserScheme} editUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserAdminUsersUserIdPut(userId: any, editUserScheme: EditUserScheme, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserAdminUsersUserIdPut(userId, editUserScheme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Creates a new user.  Args:     new_user (UserCreateSchema): data for the new user.     db_session (CustomSession).     jwt_payload (dict).  Returns:     UserResponse: Newly created user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Creates a new user
         * @param {CreateUserScheme} createUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAdminUsersPost(createUserScheme: CreateUserScheme, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUserAdminUsersPost(createUserScheme, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a user from the system  Args:     user_id (UUID): The ID of the user to be deleted.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Delete a user
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAdminUsersUserIdDelete(userId: any, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.deleteUserAdminUsersUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a user by their ID.  Args:     user_id (UUID): The user ID.     db_session (CustomAsyncSession): The database session.     jwt_payload (dict): The JWT payload obtained by the `JWTBearerAdmin`     dependency.  Returns:     UserResponse: A response object containing the user\'s information.  Raises:     HTTPException: If the user with the specified ID is not found.
         * @summary Get the user info
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdminUsersUserIdGet(userId: any, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUserAdminUsersUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of users filtered by various options. This endpoint allows you to retrieve a list of users based on various filter criteria. The following filters are available:  Args:     phone: Filter by phone number (string)     email: Filter by email address (string)     email_verified: Filter by email verification status (boolean, example:     true)     phone_verified: Filter by phone verification status (boolean, example:     true)     role_id: Filter by user role (integer)     created_at: Filter by creation date (datetime)     updated_at: Filter by update date (datetime)     db_session (CustomSession)     params (UserCreateSchema): Filter params the same as args     jwt_payload (dict[str]): bearer token payload  Returns:     UserListResponse: A response object containing the list of users and     the applied filters.
         * @summary Get users
         * @param {any | null} [phone] Filter by phone number
         * @param {any | null} [email] Filter by email address
         * @param {any | null} [emailVerified] Filter by email verification status
         * @param {any | null} [phoneVerified] Filter by phone verification status
         * @param {any | null} [roleId] Filter by user role
         * @param {any | null} [createdAt] Filter by creation date
         * @param {any | null} [updatedAt] Filter by update date
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersAdminUsersGet(phone?: any | null, email?: any | null, emailVerified?: any | null, phoneVerified?: any | null, roleId?: any | null, createdAt?: any | null, updatedAt?: any | null, page?: any, size?: any, options?: any): AxiosPromise<UserListResponseInput> {
            return localVarFp.listUsersAdminUsersGet(phone, email, emailVerified, phoneVerified, roleId, createdAt, updatedAt, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user information  Args:     user_id(UUID): user id for update     updated_user (EditUserScheme): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     UserResponse: The updated user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Change the user info
         * @param {any} userId 
         * @param {EditUserScheme} editUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAdminUsersUserIdPut(userId: any, editUserScheme: EditUserScheme, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserAdminUsersUserIdPut(userId, editUserScheme, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Creates a new user.  Args:     new_user (UserCreateSchema): data for the new user.     db_session (CustomSession).     jwt_payload (dict).  Returns:     UserResponse: Newly created user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
     * @summary Creates a new user
     * @param {CreateUserScheme} createUserScheme 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserAdminUsersPost(createUserScheme: CreateUserScheme, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserAdminUsersPost(createUserScheme, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a user from the system  Args:     user_id (UUID): The ID of the user to be deleted.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted  Raises:     HTTPException: dynamic according to provided schemes
     * @summary Delete a user
     * @param {any} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserAdminUsersUserIdDelete(userId: any, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserAdminUsersUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a user by their ID.  Args:     user_id (UUID): The user ID.     db_session (CustomAsyncSession): The database session.     jwt_payload (dict): The JWT payload obtained by the `JWTBearerAdmin`     dependency.  Returns:     UserResponse: A response object containing the user\'s information.  Raises:     HTTPException: If the user with the specified ID is not found.
     * @summary Get the user info
     * @param {any} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserAdminUsersUserIdGet(userId: any, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserAdminUsersUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of users filtered by various options. This endpoint allows you to retrieve a list of users based on various filter criteria. The following filters are available:  Args:     phone: Filter by phone number (string)     email: Filter by email address (string)     email_verified: Filter by email verification status (boolean, example:     true)     phone_verified: Filter by phone verification status (boolean, example:     true)     role_id: Filter by user role (integer)     created_at: Filter by creation date (datetime)     updated_at: Filter by update date (datetime)     db_session (CustomSession)     params (UserCreateSchema): Filter params the same as args     jwt_payload (dict[str]): bearer token payload  Returns:     UserListResponse: A response object containing the list of users and     the applied filters.
     * @summary Get users
     * @param {any | null} [phone] Filter by phone number
     * @param {any | null} [email] Filter by email address
     * @param {any | null} [emailVerified] Filter by email verification status
     * @param {any | null} [phoneVerified] Filter by phone verification status
     * @param {any | null} [roleId] Filter by user role
     * @param {any | null} [createdAt] Filter by creation date
     * @param {any | null} [updatedAt] Filter by update date
     * @param {any} [page] 
     * @param {any} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUsersAdminUsersGet(phone?: any | null, email?: any | null, emailVerified?: any | null, phoneVerified?: any | null, roleId?: any | null, createdAt?: any | null, updatedAt?: any | null, page?: any, size?: any, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listUsersAdminUsersGet(phone, email, emailVerified, phoneVerified, roleId, createdAt, updatedAt, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user information  Args:     user_id(UUID): user id for update     updated_user (EditUserScheme): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     UserResponse: The updated user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
     * @summary Change the user info
     * @param {any} userId 
     * @param {EditUserScheme} editUserScheme 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserAdminUsersUserIdPut(userId: any, editUserScheme: EditUserScheme, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserAdminUsersUserIdPut(userId, editUserScheme, options).then((request) => request(this.axios, this.basePath));
    }
}



