export class IntegrationService {
    static storage = localStorage

    static setIntegrationToken(integrationToken: string) {
        this.storage.setItem('integrationToken', integrationToken)
    }

    static getIntegrationToken() {
        return this.storage.getItem('integrationToken') ?? ''
    }

    static isEmpty(){
        return IntegrationService.getIntegrationToken() === ''
    }

    static removeIntegrationToken() {
        this.storage.removeItem('integrationToken')
    }
}
