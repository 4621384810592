import {createAsyncThunk} from "@reduxjs/toolkit";
import {requestHandle} from "@/utils/requestHandle";
import {Api} from "@/api/apiService";
import {
    IEditCredentialsParams,
    IDeleteCredentialsParams,
    IGetCredentialParams
} from "@/store/actionTypes/oauthCredentials";

export const getCredential = createAsyncThunk(
    'oauthCredentials/getCredential',
    async (args: IGetCredentialParams, thunkAPI) => {
        const response = await requestHandle(() => Api.OAuthCredentials.getCredentialIntegrationOauthCredentialsProviderNameGet(args.providerName), thunkAPI)
        return response
    }
)

export const getCredentials = createAsyncThunk(
    'oauthCredentials/getCredentials',
    async (_, thunkAPI) => {
        const response = await requestHandle(() => Api.OAuthCredentials.getCredentialsIntegrationOauthCredentialsGet(), thunkAPI)
        return response
    }
)

export const createCredentials = createAsyncThunk(
    'oauthCredentials/createCredentials',
    async (args: IEditCredentialsParams, thunkAPI) => {
        const response = await requestHandle(() => Api.OAuthCredentials.createCredentialsIntegrationOauthCredentialsPost(args), thunkAPI)
        return response
    }
)

export const updateCredentials = createAsyncThunk(
    'oauthCredentials/updateCredentials',
    async (args: IEditCredentialsParams, thunkAPI) => {
        const response = await requestHandle(() => Api.OAuthCredentials.updateCredentialsIntegrationOauthCredentialsPut(args), thunkAPI)
        return response
    }
)

export const deleteCredentials = createAsyncThunk(
    'oauthCredentials/deleteCredentials',
    async (args: IDeleteCredentialsParams, thunkAPI) => {
        const response = await requestHandle(() => Api.OAuthCredentials.deleteCredentialsIntegrationOauthCredentialsDelete(args.provider), thunkAPI)
        return response
    }
)
