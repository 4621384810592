import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    getIntegrationServiceInfo,
    createIntegrationServiceByName,
    deleteIntegrationService,
} from '@/store/entities/integration/actions'
import {IBaseStateField, IResponse} from "@/types/type";
import {getNewStateByStatus} from "@/utils/getNewStateByStatus";
import {ICreateIntegrationServiceByName, ICreateIntegrationServiceByNameData, IGetIntegrationServiceData, IIntegrationServiceInfo} from "@/store/actionTypes/integration";
import {IntegrationService} from '@/api/IntegrationService'

interface IIntegrationState {
    getIntegrationServiceInfoState: IBaseStateField
    integrationServiceInfo: IIntegrationServiceInfo | null
    createIntegrationServiceByNameState: IBaseStateField
    createIntegrationServiceByNameData: ICreateIntegrationServiceByName | null
    deleteIntegrationServiceState: IBaseStateField
}

const initialState: IIntegrationState = {
    getIntegrationServiceInfoState: getNewStateByStatus('base'),
    integrationServiceInfo: null,
    createIntegrationServiceByNameState: getNewStateByStatus('base'),
    createIntegrationServiceByNameData: null,
    deleteIntegrationServiceState: getNewStateByStatus('base'),
}

export const integrationSlice = createSlice({
    name: "integration",
    initialState,
    reducers: {
        resetIntegrationStates: (state) => {
            state.deleteIntegrationServiceState = getNewStateByStatus('base')
            state.getIntegrationServiceInfoState = getNewStateByStatus('base')
            state.createIntegrationServiceByNameState = getNewStateByStatus('base')
        }
    },
    extraReducers: {
        [getIntegrationServiceInfo.pending.type]: (state) => {
            state.getIntegrationServiceInfoState = getNewStateByStatus('pending')
        },
        [getIntegrationServiceInfo.fulfilled.type]: (state, action: PayloadAction<IResponse<IGetIntegrationServiceData>>) => {
            state.getIntegrationServiceInfoState = getNewStateByStatus('fulfilled')
            state.integrationServiceInfo = action.payload.data.integrationService
        },
        [getIntegrationServiceInfo.rejected.type]: (state)  => {
            state.getIntegrationServiceInfoState = getNewStateByStatus('rejected')
        },
        [createIntegrationServiceByName.pending.type]: (state) => {
            state.createIntegrationServiceByNameState = getNewStateByStatus('pending')
        },
        [createIntegrationServiceByName.fulfilled.type]: (state, action: PayloadAction<IResponse<ICreateIntegrationServiceByNameData>>) => {
            state.createIntegrationServiceByNameState = getNewStateByStatus('fulfilled')
            state.createIntegrationServiceByNameData = action.payload.data.integration_service
            IntegrationService.setIntegrationToken(action.payload.data.integration_service.api_key)
        },
        [createIntegrationServiceByName.rejected.type]: (state)  => {
            state.createIntegrationServiceByNameState = getNewStateByStatus('rejected')
        },
        [deleteIntegrationService.pending.type]: (state) => {
            state.deleteIntegrationServiceState = getNewStateByStatus('pending')
        },
        [deleteIntegrationService.fulfilled.type]: (state) => {
            state.deleteIntegrationServiceState = getNewStateByStatus('fulfilled')
            IntegrationService.removeIntegrationToken()
        },
        [deleteIntegrationService.rejected.type]: (state) => {
            state.deleteIntegrationServiceState = getNewStateByStatus('rejected')
        }
    }
})
