import {IPaginationData} from '@/types/type'

import {createAsyncThunk} from '@reduxjs/toolkit'

import {Api} from '@/api/apiService'

import {requestHandle} from '@/utils/requestHandle'

import {IEvent} from '@/store/actionTypes/events'

export const getEvents = createAsyncThunk(
    'events/getEvents',
    async (data: IPaginationData, thunkAPI) => {
        const response = await requestHandle(() => Api.Events.getEventsEventsGet(data.page, data.perPage), thunkAPI)
        return response
    }
)

export const createEvent = createAsyncThunk(
    'events/createEvent',
    async (data: Omit<IEvent, 'id'>, thunkAPI) => {
        const response = await requestHandle(() => Api.Events.createEventsPost(data), thunkAPI)
        return response
    }
)

export const deleteEvent = createAsyncThunk(
    'events/deleteEvent',
    async (data: Pick<IEvent, 'id'>, thunkAPI) => {
        const response = await requestHandle(() => Api.Events.deleteEventsEventIdDelete(data.id), thunkAPI)
        return response
    }
)

export const editEvent = createAsyncThunk(
    'events/editEvent',
    async (data: IEvent, thunkAPI) => {
        const response = await requestHandle(() => Api.Events.updateEventsEventIdPatch(data.id, data), thunkAPI)
        return response
    }
)
