import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IBaseStateField, IResponse} from "@/types/type";
import {getNewStateByStatus} from "@/utils/getNewStateByStatus";
import {getProviders} from "@/store/entities/oauth/actions";
import {IGetProvidersData, IProvider} from "@/store/actionTypes/oauth";

interface IOauthState {
    getProvidersState: IBaseStateField;
    providers: IProvider[] | null
}

const initialState: IOauthState = {
    getProvidersState: getNewStateByStatus('base'),
    providers: null
}

export const oauthSlice = createSlice({
    name: "oauth",
    initialState,
    reducers: {},
    extraReducers: {
        [getProviders.pending.type]: (state) => {
            state.getProvidersState = getNewStateByStatus('pending')
        },
        [getProviders.fulfilled.type]: (state, action: PayloadAction<IResponse<IGetProvidersData>>) => {
            state.getProvidersState = getNewStateByStatus('fulfilled')
            state.providers = action.payload.data.list
        },
        [getProviders.rejected.type]: (state) => {
            state.getProvidersState = getNewStateByStatus('rejected')
        }
    }
})
