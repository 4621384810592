import {createAsyncThunk} from "@reduxjs/toolkit";
import {Api} from "@/api/apiService";
import {requestHandle} from "@/utils/requestHandle";

export const getIntegrationServiceInfo = createAsyncThunk(
    'integration/getIntegrationServiceInfo',
    async (_, thunkAPI) => {
        const response = await requestHandle(() => Api.ServiceIntegration.getIntegrationServiceInfoIntegrationServicesGet(), thunkAPI)
        return response
    }
)

export const createIntegrationServiceByName = createAsyncThunk(
    'integration/createIntegrationServiceByName',
    async (name: string, thunkAPI) => {
        const response = await requestHandle(() => Api.ServiceIntegration.createIntegrationServiceIntegrationServicesPost({name: name}), thunkAPI)
        return response
    }
)

export const deleteIntegrationService = createAsyncThunk(
    'integration/deleteIntegrationService',
    async (_, thunkAPI) => {
        const response = await requestHandle(() => Api.ServiceIntegration.deleteIntegrationServiceIntegrationServicesDelete(), thunkAPI)
        return response
    }
)
