import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IBaseStateField, IResponse} from "@/types/type";
import {getNewStateByStatus} from "@/utils/getNewStateByStatus";
import {
    createCredentials, deleteCredentials,
    getCredential,
    getCredentials, updateCredentials
} from "@/store/entities/oauthCredentials/actions";
import {
    IGetCredentialData,
    IGetCredentialsData
} from "@/store/actionTypes/oauthCredentials";

interface IOAuthCredentialsSlice {
    getCredentialState: IBaseStateField
    getCredentialsState: IBaseStateField
    createCredentialsState: IBaseStateField,
    updateCredentialsState: IBaseStateField,
    deleteCredentialsState: IBaseStateField,
    credentials: IGetCredentialsData['credentials'] | null
}

const initialState: IOAuthCredentialsSlice = {
    getCredentialState: getNewStateByStatus('base'),
    getCredentialsState: getNewStateByStatus('base'),
    createCredentialsState: getNewStateByStatus('base'),
    updateCredentialsState: getNewStateByStatus('base'),
    deleteCredentialsState: getNewStateByStatus('base'),
    credentials: null,
}

export const oauthCredentialsSlice = createSlice({
    name: "oauthCredentialsSlice",
    initialState,
    reducers: {
    },
    extraReducers: {
        [getCredential.pending.type]: (state) => {
            state.getCredentialState = getNewStateByStatus('pending')
        },
        [getCredential.fulfilled.type]: (state, action: PayloadAction<IResponse<IGetCredentialData>>) => {
            state.getCredentialState = getNewStateByStatus('fulfilled')
            state.credentials = [action.payload.data.credentials]
        },
        [getCredential.rejected.type]: (state) => {
            state.getCredentialState = getNewStateByStatus('rejected')
            state.credentials = null
        },
        [getCredentials.pending.type]: (state) => {
            state.getCredentialsState = getNewStateByStatus('pending')
        },
        [getCredentials.fulfilled.type]: (state, action: PayloadAction<IResponse<IGetCredentialsData>>) => {
            state.getCredentialsState = getNewStateByStatus('fulfilled')
            state.credentials = action.payload.data.credentials
        },
        [getCredentials.rejected.type]: (state) => {
            state.getCredentialsState = getNewStateByStatus('rejected')
            state.credentials = null
        },
        [createCredentials.pending.type]: (state) => {
            state.createCredentialsState = getNewStateByStatus('pending')
        },
        [createCredentials.fulfilled.type]: (state) => {
            state.createCredentialsState = getNewStateByStatus('fulfilled')
        },
        [createCredentials.rejected.type]: (state) => {
            state.createCredentialsState = getNewStateByStatus('rejected')
        },
        [updateCredentials.pending.type]: (state) => {
            state.updateCredentialsState = getNewStateByStatus('pending')
        },
        [updateCredentials.fulfilled.type]: (state) => {
            state.updateCredentialsState = getNewStateByStatus('fulfilled')
        },
        [updateCredentials.rejected.type]: (state) => {
            state.updateCredentialsState = getNewStateByStatus('rejected')
        },
        [deleteCredentials.pending.type]: (state) => {
            state.deleteCredentialsState = getNewStateByStatus('pending')
        },
        [deleteCredentials.fulfilled.type]: (state) => {
            state.deleteCredentialsState = getNewStateByStatus('fulfilled')
        },
        [deleteCredentials.rejected.type]: (state) => {
            state.deleteCredentialsState = getNewStateByStatus('rejected')
        },
    }
})
