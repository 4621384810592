import {lazy} from 'react'

import {AppPaths} from './routes-enums'

// const HomePage = lazy(() => import('@/pages/Home/Home'))
const OAuthCredentials = lazy(() => import('@/pages/OAuthCredentials/OAuthCredentials'))
const IntegrationServicePage = lazy(
    () => import('@/pages/IntegrationServicePage/IntegrationServicePage')
)
const IntegrationKeysPage = lazy(() => import('@/pages/IntegrationKeysPage/IntegrationKeysPage'))
const EventsPage = lazy(() => import('@/pages/EventsPage/EventsPage'))
const FlowsPage = lazy(() => import('@/pages/FlowsPage/FlowsPage'))
const FlowsTriggersPage = lazy(() => import('@/pages/FlowsTriggersPage/FlowsTriggersPage'))

export interface IRoute {
    path: string
    component: JSX.Element
}

export const routes: IRoute[] = [
    { path: AppPaths.OAUTH_CREDENTIALS, component: <OAuthCredentials /> },
    { path: AppPaths.INTEGRATION_SERVICE, component: <IntegrationServicePage /> },
    { path: AppPaths.INTEGRATION_KEYS, component: <IntegrationKeysPage /> },
    { path: AppPaths.EVENTS, component: <EventsPage /> },
    { path: AppPaths.FLOWS, component: <FlowsPage /> },
    { path: AppPaths.FLOWS_TRIGGERS, component: <FlowsTriggersPage /> }
]

export const publicRoutes: IRoute[] = []

export const privateRoutes: IRoute[] = [
    // {path: AppPaths.HOME, component: <HomePage />},
]

export const adminRoutes: IRoute[] = [

]

export const FORBIDDEN_USER = [
    // AppPaths.ADMIN_CONTENT,
]
export const FORBIDDEN_MODERATOR: string[] = []
export const FORBIDDEN_ADMIN = []
