import {combineReducers} from 'redux'

import {eventsSlice} from '@/store/entities/events/eventsSlice'
import {flowsSlice} from '@/store/entities/flows/flowsSlice'
import {flowsTriggersSlice} from '@/store/entities/flowsTriggers/flowsTriggersSlice'
import {integrationSlice} from "@/store/entities/integration/integrationSlice";
import {integrationKeysSlice} from '@/store/entities/integrationKeys/integrationKeysSlice'
import {oauthSlice} from "@/store/entities/oauth/oauthSlice";
import {oauthCredentialsSlice} from "@/store/entities/oauthCredentials/oauthCredentialsSlice";

// ROOT REDUCER EXAMPLE

// 1. Import your reducers from entities

// import cartReducer from './entities/cart/reducers';

// 2. Define reducers into common object
const rootReducer = combineReducers({
    // users: usersReducer,
    integration: integrationSlice.reducer,
    oauth: oauthSlice.reducer,
    oauthCredentials: oauthCredentialsSlice.reducer,
    integrationKeys: integrationKeysSlice.reducer,
    events: eventsSlice.reducer,
    flows: flowsSlice.reducer,
    flowsTriggers: flowsTriggersSlice.reducer,
})

export default rootReducer
export type IRootReducer = ReturnType<typeof rootReducer>
