import {FC, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import {Center, Loader} from '@mantine/core'
import {showNotification} from '@mantine/notifications'

import {AppPaths} from './routes-enums'

import {useAppDispatch, useAppSelector} from '@/store/hooks'

interface Props {
    children: JSX.Element
}

export const PrivateRoutes: FC<Props> = ({children}) => {
    return (
        <>
        </>
    )
}
