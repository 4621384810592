import {AxiosError, AxiosResponse} from "axios";
import {IThunkAPI} from "@/store/types/IThunkApi";

export const requestHandle = async <T>(request: () => Promise<AxiosResponse<T>>, thunkAPI: IThunkAPI) => {
    try {
        const response = await request();
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
}
